import { ROOM_SIZES } from 'config'

const getAvailableBedroomOptions = (asset) => {
  const availableOptions = []

  Object.keys(ROOM_SIZES).forEach((sizeKey) => {
    if (
      Object.prototype.hasOwnProperty.call(asset, sizeKey) &&
      asset[sizeKey] > 0
    ) {
      availableOptions.push(ROOM_SIZES[sizeKey])
    }
  })

  return availableOptions
}

export default getAvailableBedroomOptions
