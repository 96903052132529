import { mapValues } from 'lodash'
import { mapProps } from 'recompose'
import convertFromJsonApi from './convertFromJsonApi.js'

// HOC that converts all props from Json Api format, if necessary

function withJsonApiProps() {
  return mapProps((props) => mapValues(props, convertFromJsonApi))
}

export default withJsonApiProps
