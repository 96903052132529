import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { ZeroIncomeSectionsAccordion } from 'components'

const propTypes = {
  householdMember: Types.householdMember.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
  zeroIncomeHouseholdMemberSections: PropTypes.array.isRequired,
}

const defaultProps = {}

function ZeroIncomeAccordionItem({
  householdMember,
  handleSubmitCrud,
  setSectionCompletionStatus,
  zeroIncomeHouseholdMemberSections,
}) {
  const heading = `${householdMember.firstName} ${householdMember.lastName}`
  return (
    <AccordionItem className="form-section" uuid={householdMember.id}>
      <div className="row">
        <AccordionItemHeading aria-level={2} className="col-12">
          <AccordionItemButton>{heading}</AccordionItemButton>
        </AccordionItemHeading>
      </div>
      <AccordionItemPanel>
        <ZeroIncomeSectionsAccordion
          householdMember={householdMember}
          handleSubmitCrud={handleSubmitCrud}
          setSectionCompletionStatus={setSectionCompletionStatus}
          zeroIncomeHouseholdMemberSections={zeroIncomeHouseholdMemberSections}
        />
      </AccordionItemPanel>
    </AccordionItem>
  )
}

ZeroIncomeAccordionItem.propTypes = exact(propTypes)
ZeroIncomeAccordionItem.defaultProps = defaultProps

export default React.memo(ZeroIncomeAccordionItem)
