import React from 'react'
import { compose } from 'recompose'
import { withJsonApiProps, withLayout, push } from 'utils'
import { ApplicationLayout } from 'components'
import { CaseNumberForm } from 'forms'
import { loginLogo } from 'images'

const propTypes = {}

const defaultProps = {}

function CaseNumber() {
  return (
    <>
      <div className="login-background" />
      <div className="login-container">
        <img className="login-logo" src={loginLogo} alt="" />
        <h1 className="login-title">Log In</h1>
        <CaseNumberForm
          initialValues={{ caseNumber: '' }}
          onSubmit={({ caseNumber }) => push(`/login/${caseNumber}`)}
        />
      </div>
    </>
  )
}

CaseNumber.propTypes = propTypes
CaseNumber.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(CaseNumber)
