import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components'
import { SubmitButton, ButtonArea } from 'lp-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  caseNumber: Yup.string().required('Case Number is required'),
})

function CaseNumberForm({ onSubmit, initialValues }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Input name="caseNumber" label="Case Number" required />
          <ButtonArea>
            <SubmitButton
              submitting={isSubmitting}
              className="button-full-width"
            >
              Submit
            </SubmitButton>
          </ButtonArea>
        </Form>
      )}
    </Formik>
  )
}

CaseNumberForm.propTypes = propTypes
CaseNumberForm.defaultProps = defaultProps

export default React.memo(CaseNumberForm)
