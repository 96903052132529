export { default as usHousingConsultantsLogo } from '../../public/images/us-housing-logo.svg'

export { default as loginLogo } from '../../public/images/login-logo.svg'
export { default as loginBackground } from '../../public/images/login-background.png'
export { default as welcomePageImage } from '../../public/images/welcome-page-image.svg'
export { default as gatherYourDocumentsImage } from '../../public/images/gather-your-documents-image.svg'
export { default as checkmark } from '../../public/images/checkmark.svg'
export { default as applicationComplete } from '../../public/images/application-complete.svg'
export { default as documentIcon } from '../../public/images/document-icon.svg'
export { default as trashIcon } from '../../public/images/trash-icon.svg'
export { default as viewIcon } from '../../public/images/view-icon.svg'
