import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames'
import { APPLICATION_OVERVIEW_PATH } from 'config'
import { Button } from 'lp-components'
import { logout } from 'api'
import { checkmark } from 'images'
import { push, useFlashMessages, useOrderedSteps } from 'utils'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
}
const defaultProps = {}

function Sidebar({ currentPath, toggleSidebar, currentStep, caseNumber }) {
  const { showFlashFailure } = useFlashMessages()
  const [steps] = useOrderedSteps()
  const stepIndex = steps.findIndex((step) => step.name === currentStep)

  const getPath = (stepNumber, path) => {
    if (stepNumber <= stepIndex + 1) {
      return path
    }
  }

  return (
    <>
      <aside className="sidebar">
        <div className="sidebar-group">
          <h2 className="sidebar-title">Housing Application</h2>
          <dl className="case-number">
            <dt>Case Number:</dt>
            <dd>{caseNumber}</dd>
          </dl>
          <ul>
            {steps.map(({ name, path }, index) => {
              const stepNumber = index + 1
              const stepNumberWithLeadingZero = String(stepNumber).padStart(
                2,
                '0'
              )
              const isStepActive = currentPath === path
              const isCompleted = stepIndex + 1 > stepNumber
              const stepPath = getPath(stepNumber, path)
              return (
                <li key={name}>
                  <a
                    href={stepPath}
                    className={classNames('sidebar-link', {
                      active: isStepActive,
                      complete: isCompleted,
                      clickable: !!stepPath,
                    })}
                  >
                    <div className="step-number-box">
                      {isCompleted ? (
                        <img className="checkmark" src={checkmark} alt="" />
                      ) : (
                        <>{stepNumberWithLeadingZero}</>
                      )}
                    </div>
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="sidebar-footer">
          <div className="application-overview-back">
            <a href={APPLICATION_OVERVIEW_PATH}>Application Overview</a>
          </div>
          <Button
            className="button-primary-outline"
            onClick={async () => {
              try {
                await logout()
                push(`/login/${caseNumber}`)
              } catch (error) {
                showFlashFailure(error.response.data.message)
              }
            }}
          >
            Log Out
          </Button>
        </div>
      </aside>
      <button
        type="button"
        onClick={toggleSidebar}
        className="sidebar-overlay"
        aria-label="Close sidebar"
      />
    </>
  )
}

Sidebar.propTypes = exact(propTypes)
Sidebar.defaultProps = defaultProps

export default Sidebar
