function createInitialIncomeAdditionalDetails(subQuestions) {
  const initialAdditionalDetails = {}
  if (subQuestions) {
    subQuestions.forEach(({ subQuestionType, displayType }) => {
      initialAdditionalDetails[subQuestionType] = ''
      if (displayType === 'additionalInformationField') {
        initialAdditionalDetails[`${subQuestionType}Details`] = ''
      }
    })
  }
  return initialAdditionalDetails
}

export default createInitialIncomeAdditionalDetails
