function dataURLToFile(file, prefix = null) {
  const { url, name, type } = file
  const arr = url.split(',')
  const bstr = Buffer.from(arr[1], 'base64')
  const u8arr = new Uint8Array(bstr)
  const newFileName = prefix ? `${prefix}_${name}` : name
  return new File([u8arr], newFileName, { type })
}

export default dataURLToFile
