import { omit } from 'lodash'

function updateTenantParams(tenant) {
  const tenantParams = omit(tenant, ['sameAddressToggle'])

  if (tenant.sameAddressToggle) {
    return {
      ...tenantParams,
      physicalAddress1: tenant.mailingAddress1,
      physicalAddress2: tenant.mailingAddress2,
      physicalCity: tenant.mailingCity,
      physicalState: tenant.mailingState,
      physicalZip: tenant.mailingZip,
    }
  }

  return tenantParams
}

export default updateTenantParams
