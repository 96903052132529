import React from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  goToNextStep,
  useFlashMessages,
  groupFilesByPersonalVerificationCategory,
} from 'utils'
import { ApplicationLayout } from 'components'
import { PersonalVerificationForm } from 'forms'
import { STEP, SAVE_ERROR_MESSAGE } from 'config'
import { isEmpty, reduce } from 'lodash'
import { uploadFiles } from 'api'

const propTypes = {
  tenantCaseFiles: PropTypes.arrayOf(Types.file).isRequired,
  certType: PropTypes.string,
}
const defaultProps = {}

function PersonalVerification({ tenantCaseFiles, certType }) {
  const { showFlashFailure } = useFlashMessages()
  const personalVerificationFiles =
    groupFilesByPersonalVerificationCategory(tenantCaseFiles)

  const categorizeNewFiles = (newFiles) => {
    return reduce(
      newFiles,
      (results, files, category) => {
        const newFiles = files.filter((file) => !file.contentDocumentId)
        if (isEmpty(newFiles)) return results

        return {
          ...results,
          [category]: newFiles,
        }
      },
      {}
    )
  }

  return (
    <>
      <Helmet>
        <title>Personal Verification</title>
      </Helmet>
      <div className="page-header">
        <h1>Personal Verification</h1>
        <p>Below, you will need to upload files for each household member.</p>
      </div>
      <PersonalVerificationForm
        initialValues={{ personalVerificationFiles }}
        certType={certType}
        onSubmit={async ({ personalVerificationFiles }) => {
          const categorizedNewFiles = categorizeNewFiles(
            personalVerificationFiles
          )

          if (!isEmpty(categorizedNewFiles)) {
            try {
              for (const category in categorizedNewFiles) {
                const files = categorizedNewFiles[category]
                await uploadFiles({ files, prefix: category })
              }
            } catch {
              return showFlashFailure(SAVE_ERROR_MESSAGE)
            }
          }
          goToNextStep(STEP.REVIEW_AND_SIGN)
        }}
      />
    </>
  )
}

PersonalVerification.propTypes = propTypes
PersonalVerification.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(PersonalVerification)
