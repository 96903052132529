import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function FileUploadAccordion({ heading, children }) {
  return (
    <Accordion
      allowZeroExpanded
      className="accordion file-upload-notes notes-container"
    >
      <AccordionItem>
        <AccordionItemHeading aria-level={3}>
          <AccordionItemButton>{heading}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

FileUploadAccordion.propTypes = exact(propTypes)
FileUploadAccordion.defaultProps = defaultProps

export default React.memo(FileUploadAccordion)
