import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  APPLICATION_INFORMATION_QUESTIONS,
  NEW_TENANT_CERT_TYPES,
} from 'config'

const propTypes = {
  tenantApplication: Types.tenantApplication.isRequired,
  certType: PropTypes.string,
}

const defaultProps = {}

function ReviewApplicationInformation({ tenantApplication, certType }) {
  const isNewTenant = NEW_TENANT_CERT_TYPES.includes(certType)
  return (
    <dl>
      {APPLICATION_INFORMATION_QUESTIONS.map(({ label, name }) => {
        const response =
          name === 'bedroomSizes' && isNewTenant
            ? tenantApplication[name]?.join(', ')
            : tenantApplication[name]
        if (!response) return null

        return (
          <React.Fragment key={label}>
            <dt>{label}</dt>
            <dd>{response}</dd>
          </React.Fragment>
        )
      })}
    </dl>
  )
}

ReviewApplicationInformation.propTypes = exact(propTypes)
ReviewApplicationInformation.defaultProps = defaultProps

export default React.memo(ReviewApplicationInformation)
