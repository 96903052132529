import React from 'react'
import exact from 'prop-types-exact'
import { Textarea, PhoneInput } from 'components'

const propTypes = {}

const defaultProps = {}

function RequestCallBackFormFields() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <PhoneInput
            name="phoneNumber"
            label="Callback phone number"
            placeholder="555-555-5555"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Textarea
            name="comments"
            label="Comments"
            placeholder="Provide us with a few words about how we can best assist you and the best time to reach you."
          />
        </div>
      </div>
    </>
  )
}

RequestCallBackFormFields.propTypes = exact(propTypes)
RequestCallBackFormFields.defaultProps = defaultProps

export default React.memo(RequestCallBackFormFields)
