import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  groupFilesByPersonalVerificationCategory,
  stripPersonalVerificationPrefixFromFileName,
} from 'utils'
import { isEmpty, map, startCase } from 'lodash'
import { PERSONAL_VERIFICATION_FILE_CATEGORIES } from 'config'

const propTypes = {
  tenantCaseFiles: PropTypes.arrayOf(Types.file).isRequired,
}

const defaultProps = {}

function ReviewPersonalVerification({ tenantCaseFiles }) {
  const personalVerificationFiles =
    groupFilesByPersonalVerificationCategory(tenantCaseFiles)

  return map(PERSONAL_VERIFICATION_FILE_CATEGORIES, (category, label) => {
    const files = personalVerificationFiles[category]
    if (isEmpty(files)) return null

    return (
      <dl key={category}>
        <dt>{startCase(label)}</dt>
        {files.map((file) => (
          <dd key={file.id}>
            {stripPersonalVerificationPrefixFromFileName(file.name)}
          </dd>
        ))}
      </dl>
    )
  })
}

ReviewPersonalVerification.propTypes = exact(propTypes)
ReviewPersonalVerification.defaultProps = defaultProps

export default React.memo(ReviewPersonalVerification)
