import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { AssetField } from 'components'
import { StepForm } from 'forms'
import { FieldArray } from 'formik'
import { HOUSEHOLD_ASSETS_QUESTIONS } from 'config'
import { findIndex } from 'lodash'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  previousStep: PropTypes.object.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  householdAssets: Yup.array().of(
    Yup.object({
      applies: Yup.boolean().required('An answer is required'),
      source: Yup.string().when('applies', {
        is: true,
        then: (schema) => schema.required('Bank is required'),
      }),
      income: Yup.string().when('applies', {
        is: true,
        then: (schema) => schema.required('Income is required'),
      }),
      amount: Yup.string().when('applies', {
        is: true,
        then: (schema) => schema.required('Amount is required'),
      }),
      shortenedType: Yup.string(),
    })
  ),
})

function HouseholdAssetsForm({ onSubmit, initialValues, previousStep }) {
  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      previousStep={previousStep}
    >
      {({ values }) => (
        <FieldArray name="householdAssets">
          <div className="form-section">
            {HOUSEHOLD_ASSETS_QUESTIONS.map(
              ({ name, label, assetLabels = {} }, index) => {
                const assetValue = values.householdAssets.find(
                  ({ shortenedType }) => shortenedType === name
                )
                const assetValueIndex = findIndex(
                  values.householdAssets,
                  assetValue
                )
                return (
                  <AssetField
                    key={name}
                    label={label}
                    applies={assetValue?.applies}
                    assetIndex={assetValueIndex}
                    questionIndex={index}
                    assetLabels={assetLabels}
                  />
                )
              }
            )}
          </div>
        </FieldArray>
      )}
    </StepForm>
  )
}

HouseholdAssetsForm.propTypes = exact(propTypes)
HouseholdAssetsForm.defaultProps = defaultProps

export default React.memo(HouseholdAssetsForm)
