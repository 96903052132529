import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { StepForm } from 'forms'
import { DocumentUploads, FileUploadAccordion } from 'components'
import {
  PERSONAL_VERIFICATION_FILE_CATEGORIES,
  STEP,
  NEW_TENANT_CERT_TYPES,
} from 'config'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  certType: PropTypes.string,
}

const defaultProps = {}

const { socialSecurityCards, birthCertificates, additionalDocuments } =
  PERSONAL_VERIFICATION_FILE_CATEGORIES
const requiredFilesSchema = Yup.array().min(1, 'A file is required')

function getValidationSchema(isNewTenant) {
  return Yup.object({
    personalVerificationFiles: Yup.object({
      [socialSecurityCards]: isNewTenant ? requiredFilesSchema : Yup.array(),
      [birthCertificates]: isNewTenant ? requiredFilesSchema : Yup.array(),
      [additionalDocuments]: Yup.array(),
    }),
  })
}

function PersonalVerificationForm({ onSubmit, certType, initialValues }) {
  const isNewTenant = NEW_TENANT_CERT_TYPES.includes(certType)
  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      previousStep={STEP.HOUSEHOLD_ASSETS}
      validationSchema={getValidationSchema(isNewTenant)}
    >
      <div className="document-section">
        <h2>Upload Document(s)</h2>
        {isNewTenant && (
          <>
            <DocumentUploads
              name="personalVerificationFiles.SSC"
              uploadDetails={{
                uploadHeader: 'Social Security Card(s)',
                uploadSubHeader:
                  'In this section, you need to provide a social security card for <strong>each member</strong> of your household.',
              }}
              hideMainHeading
            >
              <FileUploadAccordion
                heading="If one or more household member doesn't have a social security
                card, you may provide options from this list."
              >
                <ul>
                  <li>Driver's License</li>
                  <li>ID card issued by Federal, state, or local agency</li>
                  <li>A medical insurance provider</li>
                  <li>An employer or trade union</li>
                  <li>Earnings statement on payroll stubs</li>
                  <li>Bank statement</li>
                  <li>Form 1099</li>
                  <li>Benefit award letter</li>
                  <li>Retirement statement benefit letter</li>
                  <li>Life insurance policy</li>
                  <li>Court records</li>
                </ul>
              </FileUploadAccordion>
            </DocumentUploads>
            <div className="bottom-border" />

            <DocumentUploads
              name="personalVerificationFiles.BC"
              uploadDetails={{
                uploadHeader: 'Birth Certificate(s)',
                uploadSubHeader:
                  'In this section, you need to provide a birth certificate for <strong>each member</strong> of your household.',
              }}
              hideMainHeading
            >
              <FileUploadAccordion
                heading="If one or more household member doesn't have a birth
                certificate, you may provide options from this list. Click to view list."
              >
                <ul>
                  <li>Birth Certification</li>
                  <li>Baptismal Certificate</li>
                  <li>Military Discharge Papers</li>
                  <li>Valid Passport</li>
                  <li>Census Document showing age</li>
                  <li>Naturalization certificate</li>
                  <li>Social Security Administration Benefits printout</li>
                </ul>
              </FileUploadAccordion>
            </DocumentUploads>
            <div className="bottom-border" />
          </>
        )}

        <DocumentUploads
          name="personalVerificationFiles.AD"
          uploadDetails={{
            uploadHeader: 'Additional Documents',
            uploadSubHeader:
              'This can include anything that your interviewer may ask you to provide.',
          }}
          hideMainHeading
        />
      </div>
    </StepForm>
  )
}

PersonalVerificationForm.propTypes = exact(propTypes)
PersonalVerificationForm.defaultProps = defaultProps

export default React.memo(PersonalVerificationForm)
