import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { convertBoolToString } from 'utils'

const propTypes = {
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function ReviewHouseholdMembers({ householdMembers }) {
  return householdMembers.map((member, index) => {
    const isLastMember = index === householdMembers.length - 1

    return (
      <React.Fragment key={member.id}>
        <div>
          <h4>
            {member.firstName} {member.lastName}
          </h4>
          <dl>
            <dt>Relationship</dt>
            <dd>{member.relationship}</dd>
            <dt>Date of Birth</dt>
            <dd>{member.dateOfBirth}</dd>
            <dt>Is This Person a Student?</dt>
            <dd>{convertBoolToString(member.student)}</dd>
            <dt>Does This Person Have a Disability?</dt>
            <dd>{convertBoolToString(member.disabled)}</dd>
            <dt>Social Security Number</dt>
            <dd>***-**-{member.ssn.slice(5)}</dd>
            <dt>Does This Person Have Any Income?</dt>
            <dd>{convertBoolToString(member.income)}</dd>
            <dt>Race</dt>
            <dd>{member.race}</dd>
            <dt>Ethnicity</dt>
            <dd>{member.ethnicity}</dd>
            <dt>Gender</dt>
            <dd>{member.gender}</dd>
            <dt>Is This Person an Eligible Citizen?</dt>
            <dd>{member.eligibleCitizen}</dd>
            <dt>Has this member ever lived in any other state?</dt>
            <dd>{member.livedInOtherStates}</dd>
          </dl>
        </div>
        {!isLastMember && <div className="bottom-border" />}
      </React.Fragment>
    )
  })
}

ReviewHouseholdMembers.propTypes = exact(propTypes)
ReviewHouseholdMembers.defaultProps = defaultProps

export default React.memo(ReviewHouseholdMembers)
