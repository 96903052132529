import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { Button, SubmitButton, ButtonArea } from 'lp-components'
import { first, isArray, isFunction, keys, omit, values } from 'lodash'
import { push } from 'utils'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  hidePreviousButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  formRef: PropTypes.object,
  previousStep: PropTypes.object,
}

const defaultProps = {
  hidePreviousButton: false,
  submitButtonText: 'Save & Continue',
  formRef: null,
  previousStep: null,
}

function StepForm({
  onSubmit,
  initialValues,
  validationSchema,
  hidePreviousButton,
  previousStep,
  submitButtonText,
  formRef,
  children,
  ...rest
}) {
  const allowForFieldArray = isFunction(children)

  const omitValues = (values) => omit(values, ['key', 'type'])

  const cleanFormValues = (formValues) => {
    if (isArray(formValues)) {
      return formValues.map(omitValues)
    }
    return omitValues(formValues)
  }

  const beforeSubmit = (formParams) => {
    // Assumes only one key/value pair
    const formValues = first(values(formParams))
    const formKey = first(keys(formParams))
    return onSubmit({ [formKey]: cleanFormValues(formValues) })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={beforeSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      {({ isSubmitting, ...rest }) => (
        <Form noValidate>
          {allowForFieldArray ? children(rest) : children}
          <ButtonArea>
            {!hidePreviousButton && (
              <Button
                className="button-primary-outline"
                onClick={() => push(previousStep.path)}
              >
                Previous Step
              </Button>
            )}
            <SubmitButton submitting={isSubmitting}>
              {submitButtonText}
            </SubmitButton>
          </ButtonArea>
        </Form>
      )}
    </Formik>
  )
}

StepForm.propTypes = propTypes
StepForm.defaultProps = defaultProps

export default React.memo(StepForm)
