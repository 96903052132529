import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Accordion } from 'react-accessible-accordion'
import { IncomeSectionAccordionItem } from 'components'
import { kebabCase } from 'lodash'
import { HOUSEHOLD_INCOME_SECTIONS } from 'config'

const propTypes = {
  householdMember: Types.householdMember.isRequired,
  fundingProgram: PropTypes.string.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(Types.file).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setMembers: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
}

const defaultProps = {}

function IncomeSectionsAccordion({
  householdMember,
  fundingProgram,
  isLoading,
  setMembers,
  files,
  handleSubmitCrud,
  setSectionCompletionStatus,
}) {
  return (
    <Accordion className="sections-accordion" allowZeroExpanded>
      {HOUSEHOLD_INCOME_SECTIONS.map((section, index) => (
        <IncomeSectionAccordionItem
          key={section.header}
          stepNumber={String(index + 1).padStart(2, '0')}
          title={section.header}
          data={section}
          uuid={`${kebabCase(section.header)}-${householdMember.id}`}
          householdMember={householdMember}
          fundingProgram={fundingProgram}
          files={files}
          isLoading={isLoading}
          setMembers={setMembers}
          handleSubmitCrud={handleSubmitCrud}
          setSectionCompletionStatus={setSectionCompletionStatus}
        />
      ))}
    </Accordion>
  )
}

IncomeSectionsAccordion.propTypes = exact(propTypes)
IncomeSectionsAccordion.defaultProps = defaultProps

export default React.memo(IncomeSectionsAccordion)
