import axios from 'axios'
import { dataURLToFile, convertFromJsonApi, convertToCamelCase } from 'utils'

const api = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export function login(params) {
  return api.post('/login_user', params)
}

export function logout() {
  return api.get('/logout')
}

export function updateTenantApplication(params) {
  return api.patch('/tenant_application', { tenantApplication: params })
}

export function updateTenantCaseInterviewer(interviewerSfid) {
  return api.patch('/update-case-interviewer', {
    interviewerSfid: interviewerSfid,
  })
}

export function createHouseholdMembers(params) {
  return api.post('/household_members', { householdMembers: params })
}

export function updateHouseholdMembers(params) {
  return api.patch('/household_members/update_members', {
    householdMembers: params,
  })
}

export function updateHouseholdMember(params) {
  return api.patch(`/household_members/${params.id}`, {
    householdMember: params,
  })
}

export function deleteHouseholdMember(id) {
  return api.delete(`/household_members/${id}`)
}

export function updateTenant(tenant) {
  return api.patch(`/tenants/${tenant.id}`, {
    tenant: tenant,
  })
}

export function createHouseholdAssets(params) {
  return api.post('/household_assets', { householdAssets: params })
}

export function updateHouseholdAssets(params) {
  return api.patch('/household_assets/update_assets', {
    householdAssets: params,
  })
}

export function createHouseholdIncomes(params) {
  return api.post('/household_incomes', { householdIncomes: params })
}

export function updateHouseholdIncomes(params) {
  return api.patch(`/household_incomes/update_incomes`, {
    householdIncomes: params,
  })
}

export function createZeroIncomes(params) {
  return api.post('/zero_incomes', { zeroIncomes: params })
}

export function updateZeroIncomes(params) {
  return api.patch(`/zero_incomes/update_zero_incomes`, {
    zeroIncomes: params,
  })
}

export function createTenantCaseComment({ caseNumber, phoneNumber, comments }) {
  return api.post(`/tenant_cases/${caseNumber}/create_comment`, {
    phoneNumber,
    comments,
  })
}

export function completeApplication({ caseNumber }) {
  return api.patch(`/tenant_cases/${caseNumber}/complete_application`)
}

export function deleteHouseholdIncome(id) {
  return api.delete(`/household_incomes/${id}`)
}

// expects an array of salesforce object ids (sfids)
export async function fetchFiles(objectIds) {
  const params = { objectIds }
  const query_string = new URLSearchParams(params).toString()

  const response = await api.get(`/files?${query_string}`)
  const data = convertFromJsonApi(response.data.files)
  return convertToCamelCase(data)
}

export async function fetchFileDownloadDetails(fileId) {
  const params = { fileId }
  const query_string = new URLSearchParams(params).toString()

  const response = await api.get(`/files/download?${query_string}`)
  const data = convertFromJsonApi(response.data.download_details)
  return convertToCamelCase(data)
}

export async function fetchHouseholdMembers() {
  const response = await api.get('/members-for-income')
  const data = convertFromJsonApi(response.data.household_members)
  return convertToCamelCase(data)
}

export function destroyFile(contentDocumentId) {
  return api.delete(`/files/${contentDocumentId}`)
}

export function destroyFiles(contentDocumentIds) {
  const stringedIds = JSON.stringify(contentDocumentIds)
  return api.delete(`/files/${stringedIds}`)
}

export function uploadFiles({ files, objectId = null, prefix = null }) {
  const updateFiles = files.map((file) => dataURLToFile(file, prefix))
  const formData = new FormData()

  updateFiles.forEach((file, index) => {
    formData.append(`file_${index}`, file)
  })

  if (objectId) {
    formData.append('objectId', objectId)
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  return api.post(`/files/upload`, formData, {
    headers,
  })
}
