import push from './push'
import { updateTenantApplication } from 'api'

async function goToNextStep(nextStep) {
  const { name, path } = nextStep
  await updateTenantApplication({ currentStep: name })
  push(path)
}

export default goToNextStep
