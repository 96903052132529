import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { FileInput, DocumentPreview, DocumentRemove } from 'components'
import { destroyFile } from 'api'
import { Spinner } from 'lp-components'
import { FILE_SIZE_TEXT } from 'config'
import classNames from 'classnames'

const propTypes = {
  uploadDetails: Types.uploadDetails.isRequired,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  mainHeadingLevel: PropTypes.number,
  hideMainHeading: PropTypes.bool,
  children: PropTypes.node,
}

const defaultProps = {
  isLoading: false,
  mainHeadingLevel: 6,
  hideMainHeading: false,
  children: null,
}

function DocumentUploads({
  uploadDetails,
  name,
  isLoading,
  mainHeadingLevel,
  hideMainHeading,
  children,
}) {
  const { uploadHeader, uploadSubHeader } = uploadDetails

  const handleFileDelete = async (fileContentDocumentId) => {
    await destroyFile(fileContentDocumentId)
  }
  const inputHeaderId = `${name}-header`
  const Heading = `h${mainHeadingLevel}`
  return (
    <div className="flex-section">
      <div>
        <div className="document-heading">
          {!hideMainHeading && <Heading>Upload Document(s)</Heading>}
          <p className="file-type" id={inputHeaderId}>
            {uploadHeader}
          </p>
        </div>
        <p
          className={classNames({ 'restrict-width': hideMainHeading })}
          dangerouslySetInnerHTML={{ __html: uploadSubHeader }}
        />
        <p className="tiny">{FILE_SIZE_TEXT}</p>
        {children}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <FileInput
          name={name}
          label={false}
          aria-labelledby={inputHeaderId}
          multiple={true}
          selectText="Choose File(s)"
          onRemove={(event) => {
            if (!event.contentDocumentId) return
            handleFileDelete(event.contentDocumentId)
          }}
          previewComponent={(props) => <DocumentPreview {...props} />}
          removeComponent={(props) => <DocumentRemove {...props} />}
        />
      )}
    </div>
  )
}

DocumentUploads.propTypes = exact(propTypes)
DocumentUploads.defaultProps = defaultProps

export default React.memo(DocumentUploads)
