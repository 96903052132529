import React from 'react'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function ConsentDocument() {
  return (
    <>
      <h3>
        Document Package for Applicant's/Tenant's Consent to the Release of
        Information
      </h3>
      <p>This Package contains the following documents:</p>
      <ol>
        <li>HUD-9887/A Fact Sheet describing the necessary verifications</li>
        <li>Form HUD-9887 (to be signed by the Applicant or Tenant)</li>
        <li>
          Form HUD-9887-A (to be signed by the Applicant or Tenant and Housing
          Owner)
        </li>
        <li>
          Relevant Verifications (to be signed by the Applicant or Tenant)
        </li>
      </ol>
      <p>
        Each household must receive a copy of the 9887/A Fact Sheet, form
        HUD-9887, and form HUD-9887-A.
      </p>
      <h3>
        HUD-9887/A Fact Sheet | Verification of Information Provided by
        Applicants and Tenants of Assisted Housing -- What Verification Involves
      </h3>
      <p>
        To receive housing assistance, applicants and tenants who are at least
        18 years of age and each family head, spouse, or co-head, regardless of
        age, must provide the owner or management agent (O/A) or public housing
        agency (PHA) with certain information specified by the U.S. Department
        of Housing and Urban Development (HUD).
      </p>
      <p>
        To make sure that the assistance is used properly, Federal laws require
        that the information you provide be verified. This information is
        verified in two ways:
      </p>
      <p>
        HUD, O/As, and PHAs may verify the information you provide by checking
        with the records kept by certain public agencies (e.g., Social Security
        Administration (SSA), State agency that keeps wage and unemployment
        compensation claim information, and the Department of Health and Human
        Services (HHS) National Directory of New Hires (NDNH) database that
        stores wage, new hires, and unemployment compensation). HUD (only) may
        verify information covered in your tax returns from the U.S. Internal
        Revenue Service (IRS). You give your consent to the release of this
        information by signing form HUD-9887. Only HUD, O/As, and PHAs can
        receive information authorized by this form.
      </p>
      <p>
        The O/A must verify the information that is used to determine your
        eligibility and the amount of rent you pay. You give your consent to the
        release of this information by signing the form HUD-9887, the form
        HUD-9887-A, and the individual verification and consent forms that apply
        to you. Federal laws limit the kinds of information the O/A can receive
        about you. The amount of income you receive helps to determine the
        amount of rent you will pay. The O/A will verify all of the sources of
        income that you report. There are certain allowances that reduce the
        income used in determining tenant rents.
      </p>
      <p>
        Example: Mrs. Anderson is 62 years old. Her age qualifies her for a
        medical allowance. Her annual income will be adjusted because of this
        allowance. Because Mrs. Anderson's medical expenses will help determine
        the amount of rent she pays, the O/A is required to verify any medical
        expenses that she reports.
      </p>
      <p>
        Example: Mr. Harris does not qualify for the medical allowance because
        he is not at least 62 years of age, and he is not handicapped or
        disabled. Because he is not eligible for the medical allowance, the
        amount of his medical expenses does not change the amount of rent he
        pays. Therefore, the O/A cannot ask Mr. Harris anything about his
        medical expenses and cannot verify with a third party about any medical
        expenses he has.
      </p>
      <h3>Customer Protections</h3>
      <p>
        Information received by HUD is protected by the Federal Privacy Act.
        Information received by the O/A or the PHA is subject to State privacy
        laws. Employees of HUD, the O/A, and the PHA are subject to penalties
        for using these consent forms improperly. You do not have to sign the
        form HUD-9887, the form HUD-9887-A, or the individual verification
        consent forms when they are given to you at your certification or
        recertification interview. You may take them home with you to read or to
        discuss with a third party of your choice. The O/A will give you another
        date when you can return to sign these forms.
      </p>
      <p>
        If you cannot read and/or sign a consent form due to a disability, the
        O/A shall make a reasonable accommodation in accordance with Section 504
        of the Rehabilitation Act of 1973. Such accommodations may include: home
        visits when the applicant's or tenant's disability prevents him/her from
        coming to the office to complete the forms; the applicant or tenant
        authorizing another person to sign on his/her behalf; and for persons
        with visual impairments, accommodations may include providing the forms
        in large script or braille or providing readers.
      </p>
      <p>
        If an adult member of your household, due to extenuating circumstances,
        is unable to sign the form HUD-9887 or the individual verification forms
        on time, the O/A may document the file as to the reason for the delay
        and the specific plans to obtain the proper signature as soon as
        possible.
      </p>
      <p>
        The O/A must tell you, or a third party which you choose, of the
        findings made as a result of the O/A verifications authorized by your
        consent. The O/A must give you the opportunity to contest such findings
        in accordance with HUD Handbook 4350.3 Rev. 1. However, for information
        received under the form HUD-9887 or form HUD-9887-A, HUD, the O/A, or
        the PHA, may inform you of these findings.
      </p>
      <p>
        O/As must keep tenant files in a location that ensures confidentiality.
        Any employee of the O/A who fails to keep tenant information
        confidential is subject to the enforcement provisions of the State
        Privacy Act and is subject to enforcement actions by HUD. Also, any
        applicant or tenant affected by negligent disclosure or improper use of
        information may bring civil action for damages, and seek other relief,
        as may be appropriate, against the employee.
      </p>
      <p>
        HUD-9887/A requires the O/A to give each household a copy of the Fact
        Sheet and forms HUD-9887, HUD-9887-A along with appropriate individual
        consent forms. The package you will receive will include the following
        documents:
      </p>
      <ul>
        <li>
          HUD-9887/A Fact Sheet: Describes the requirement to verify information
          provided by individuals who apply for housing assistance. This fact
          sheet also describes consumer protections under the verification
          process.
        </li>
        <li>
          Form HUD-9887: Allows the release of information between government
          agencies.
        </li>
        <li>
          Form HUD-9887-A: Describes the requirement of third party verification
          along with consumer protections.
        </li>
        <li>
          Individual verification consents: Used to verify the relevant
          information provided by applicants/tenants to determine their
          eligibility and level of benefits.
        </li>
      </ul>
      <p>Consequences for Not Signing the Consent Forms</p>
      <p>
        If you fail to sign the form HUD-9887, the form HUD-9887-A, or the
        individual verification forms, this may result in your assistance being
        denied (for applicants) or your assistance being terminated (for
        tenants). See further explanation on the forms HUD-9887 and 9887-A. If
        you are an applicant and are denied assistance for this reason, the O/A
        must notify you of the reason for your rejection and give you an
        opportunity to appeal the decision.
      </p>
      <p>
        If you are a tenant and your assistance is terminated for this reason,
        the O/A must follow the procedures set out in the Lease. This includes
        the opportunity for you to meet with the O/A.
      </p>
      <p>Programs Covered by this Fact Sheet</p>
      <p>Rental Assistance Program (RAP)</p>
      <p>Rent Supplement</p>
      <p>
        Section 8 Housing Assistance Payments Programs (administered by the
        Office of Housing)
      </p>
      <p>Section 202</p>
      <p>Sections 202 and 811 PRAC</p>
      <p>Section 202/162 PAC</p>
      <p>Section 221(d)(3) Below Market Interest Rate Section 236</p>
      <p>HOPE 2 Home Ownership of Multifamily Units</p>
      <p>
        Notice and Consent for the Release of Information to the U.S. Department
        of Housing and Urban Development (HUD) and to an Owner and Management
        Agent (O/A), and to a Public Housing Agency (PHA)
      </p>
      <p>
        HUD Office requesting release of information (Owner should provide the
        full address of the HUD Field Office, Attention: Director, Multifamily
        Division.):
      </p>
      <p>
        O/A requesting release of information (Owner should provide the full
        name and address of the Owner.): [Need to Merge in Asset Name, Account
        Name, Address]
      </p>
      <p>
        PHA requesting release of information (Owner should provide the full
        name and address of the PHA and the title of the director or
        administrator. If there is no PHA Owner or PHA contract administrator
        for this project, mark an X through this entire box.):
      </p>
      <p>
        Notice To Tenant: Do not sign this form if the space above for
        organizations requesting release of information is left blank. You do
        not have to sign this form when it is given to you. You may take the
        form home with you to read or discuss with a third party of your choice
        and return to sign the consent on a date you have worked out with the
        housing owner/manager.
      </p>
      <p>
        Authority: Section 217 of the Consolidated Appropriations Act of 2004
        (Pub L. 108-199). This law is found at 42 U.S.C.653(J). This law
        authorizes HHS to disclose to the Department of Housing and Urban
        Development (HUD) information in the NDNH portion of the “Location and
        Collection System of Records” for the purposes of verifying employment
        and income of individuals participating in specified programs and, after
        removal of personal identifiers, to conduct analyses of the employment
        and income reporting of these individuals. Information may be disclosed
        by the Secretary of HUD to a private owner, a management agent, and a
        contract administrator in the administration of rental housing
        assistance.
      </p>
      <p>
        Section 904 of the Stewart B. McKinney Homeless Assistance Amendments
        Act of 1988, as amended by section 903 of the Housing and Community
        Development Act of 1992 and section 3003 of the Omnibus Budget
        Reconciliation Act of 1999. This law is found at 42 U.S.C. 3544.This law
        requires you to sign a consent form authorizing: (1) HUD and the PHA to
        request wage and unemployment compensation claim information from the
        state agency responsible for keeping that information; and (2) HUD, O/A,
        and the PHA responsible for determining eligibility to verify salary and
        wage information pertinent to the applicant's or participant's
        eligibility or level of benefits; (3) HUD to request certain tax return
        information from the U.S. Social Security Administration (SSA) and the
        U.S. Internal Revenue Service (IRS).
      </p>
      <p>
        Purpose: In signing this consent form, you are authorizing HUD, the
        above- named O/A, and the PHA to request income information from the
        government agencies listed on the form. HUD, the O/A, and the PHA need
        this information to verify your household's income to ensure that you
        are eligible for assisted housing benefits and that these benefits are
        set at the correct level. HUD, the O/A, and the PHA may participate in
        computer matching programs with these sources to verify your eligibility
        and level of benefits. This form also authorizes HUD, the O/A, and the
        PHA to seek wage, new hire (W-4), and unemployment claim information
        from current or former employers to verify information obtained through
        computer matching.
      </p>
      <p>
        Uses of Information to be Obtained: HUD is required to protect the
        income information it obtains in accordance with the Privacy Act of
        1974, 5 U.S.C. 552a. The O/A and the PHA is also required to protect the
        income information it obtains in accordance with any applicable State
        privacy law. After receiving the information covered by this notice of
        consent, HUD, the O/A, and the PHA may inform you that your eligibility
        for, or level of, assistance is uncertain and needs to be verified and
        nothing else.
      </p>
      <p>
        HUD, O/A, and PHA employees may be subject to penalties for unauthorized
        disclosures or improper uses of the income information that is obtained
        based on the consent form.
      </p>
      <p>Agencies To Provide Information</p>
      <ul>
        <li>
          State Wage Information Collection Agencies. (HUD and PHA). This
          consent is limited to wages and unemployment compensation you have
          received during period(s) within the last 5 years when you have
          received assisted housing benefits.
        </li>
        <li>
          U.S. Social Security Administration (HUD only). This consent is
          limited to the wage and self employment information from your current
          form W-2.
        </li>
        <li>
          National Directory of New Hires contained in the Department of Health
          and Human Services' system of records. This consent is limited to
          wages and unemployment compensation you have received during period(s)
          within the last 5 years when you have received assisted housing
          benefits.
        </li>
        <li>
          U.S. Internal Revenue Service (HUD only). This consent is limited to
          information covered in your current tax return. This consent is
          limited to the following information that may appear on your current
          tax return:
        </li>
      </ul>
      <ul>
        <li>
          1099-S Statement for Recipients of Proceeds from Real Estate
          Transactions
        </li>
        <li>
          1099-B Statement for Recipients of Proceeds from Real Estate Brokers
          and Barters Exchange Transactions
        </li>
        <li>
          1099-A Information Return for Acquisition or Abandonment of Secured
          Property
        </li>
        <li>1099-G Statement for Recipients of Certain Government Payments</li>
        <li>
          1099-DIV Statement for Recipients of Dividends and Distributions
        </li>
        <li>1099 INT Statement for Recipients of Interest Income</li>
        <li>1099-MISC Statement for Recipients of Miscellaneous Income</li>
        <li>1099-OID Statement for Recipients of Original Issue Discount</li>
        <li>
          1099-PATR Statement for Recipients of Taxable Distributions Received
          from Cooperatives
        </li>
        <li>1099-R Statement for Recipients of Retirement Plans</li>
        <li>W2-G Statement of Gambling Winnings</li>
        <li>1065-K1 Partners Share of Income, Credits, Deductions, etc.</li>
        <li>
          1041-K1 Beneficiary's Share of Income, Credits, Deductions, etc.
        </li>
        <li>
          1120S-K1 Shareholder's Share of Undistributed Taxable Income, Credits,
          Deductions, etc.
        </li>
      </ul>
      <p>
        I understand that income information obtained from these sources will be
        used to verify information that I provide in determining initial or
        continued eligibility for assisted housing programs and the level of
        benefits. No action can be taken to terminate, deny, suspend, or reduce
        the assistance your household receives based on information obtained
        about you under this consent until the HUD Office, Office of Inspector
        General (OIG) or the PHA (whichever is applicable) and the O/A have
        independently verified:
      </p>
      <ol>
        <li>
          the amount of the income, wages, or unemployment compensation
          involved,
        </li>
        <li>
          whether you actually have (or had) access to such income, wages, or
          benefits for your own use, and
        </li>
        <li>
          the period or periods when, or with respect to which you actually
          received such income, wages, or benefits.
        </li>
      </ol>
      <p>
        A photocopy of the signed consent may be used to request a third party
        to verify any information received under this consent (e.g., employer).
        HUD, the O/A, or the PHA shall inform you, or a third party which you
        designate, of the findings made on the basis of information verified
        under this consent and shall give you an opportunity to contest such
        findings in accordance with Handbook 4350.3 Rev. 1. If a member of the
        household who is required to sign the consent form is unable to sign the
        form on time due to extenuating circumstances, the O/A may document the
        file as to the reason for the delay and the specific plans to obtain the
        proper signature as soon as possible.
      </p>
      <p>This consent form expires 15 months after signed.</p>
      <p>
        <b>Privacy Act Statement.</b> The Department of Housing and Urban
        Development (HUD) is authorized to collect this information by the U.S.
        Housing Act of 1937, as amended (42 U.S.C. 1437 et. seq.); the Housing
        and Urban-Rural Recovery Act of 1983 (P.L. 98-181); the Housing and
        Community Development Technical Amendments of 1984 (P.L. 98-479); and by
        the Housing and Community Development Act of 1987 (42 U.S.C. 3543). The
        information is being collected by HUD to determine an applicant's
        eligibility, the recommended unit size, and the amount the tenant(s)
        must pay toward rent and utilities. HUD uses this information to assist
        in managing certain HUD properties, to protect the Government's
        financial interest, and to verify the accuracy of the information
        furnished. HUD, the owner or management agent (O/A), or a public housing
        agency (PHA) may conduct a computer match to verify the information you
        provide. This information may be released to appropriate Federal, State,
        and local agencies, when relevant, and to civil, criminal, or regulatory
        investigators and prosecutors. However, the information will not be
        otherwise disclosed or released outside of HUD, except as permitted or
        required by law. You must provide all of the information requested.
        Failure to provide any information may result in a delay or rejection of
        your eligibility approval.
      </p>
      <h3>Penalties for Misusing this Consent:</h3>
      <p>
        HUD, the O/A, and any PHA (or any employee of HUD, the O/A, or the PHA)
        may be subject to penalties for unauthorized disclosures or improper
        uses of information collected based on the consent form. Use of the
        information collected based on the form HUD 9887 is restricted to the
        purposes cited on the form HUD 9887. Any person who knowingly or
        willfully requests, obtains, or discloses any information under false
        pretenses concerning an applicant or tenant may be subject to a
        misdemeanor and fined not more than $5,000. Any applicant or tenant
        affected by negligent disclosure of information may bring civil action
        for damages, and seek other relief, as may be appropriate, against the
        officer or employee of HUD, the Owner or the PHA responsible for the
        unauthorized disclosure or improper use.
      </p>
      <h3>Applicant's/Tenant's Consent to the Release of Information</h3>
      <h3>
        Verification by Owners of Information Supplied by Individuals Who Apply
        for Housing Assistance
      </h3>
      <h3>Instructions to Owners</h3>
      <p>
        Give the documents listed below to the applicants/tenants to sign.
        Staple or clip them together in one package in the order listed:
      </p>
      <ul>
        <li>The HUD-9887/A Fact Sheet</li>
        <li>Form HUD-9887</li>
        <li>Form HUD-9887-A</li>
        <li>Relevant verifications (HUD Handbook 4350.3 Rev. 1)</li>
      </ul>
      <p>Verbally inform applicants and tenants that:</p>
      <ol>
        <li>
          They may take these forms home with them to read or to discuss with a
          third party of their choice and to return to sign them on a date they
          have worked out with you, and
        </li>
        <li>
          If they have a disability that prevents them from reading and/or
          signing any consent, that you, the Owner, are required to provide
          reasonable accommodations.
        </li>
      </ol>
      <p>
        Owners are required to give each household a copy of the HUD9887/A Fact
        Sheet, form HUD-9887, and form HUD-9887-A after obtaining the required
        applicants/tenants signature(s). Also, owners must give the
        applicants/tenants a copy of the signed individual verification forms
        upon their request.
      </p>
      <h3>Instructions to Applicants and Tenants</h3>
      <p>
        This Form HUD-9887-A contains customer information and protections
        concerning the HUD-required verifications that Owners must perform.
      </p>
      <p>Read this material which explains:</p>
      <ol>
        <li>HUD's requirements concerning the release of information, and</li>
        <li>Other customer protections.</li>
      </ol>
      <p>Sign on the last page that:</p>
      <ol>
        <li>You have read this form, or</li>
        <li>
          The Owner or a third party of your choice has explained it to you, and
        </li>
        <li>
          You consent to the release of information for the purposes and uses
          described.
        </li>
      </ol>
      <h3>
        Authority for Requiring Applicant's/Tenant's Consent to the Release of
        Information
      </h3>
      <p>
        Section 904 of the Stewart B. McKinney Homeless Assistance Amendments
        Act of 1988, as amended by section 903 of the Housing and Community
        Development Act of 1992. This law is found at 42 U.S.C. 3544. In part,
        this law requires you to sign a consent form authorizing the Owner to
        request current or previous employers to verify salary and wage
        information pertinent to your eligibility or level of benefits.
      </p>
      <p>
        In addition, HUD regulations (24 CFR 5.659, Family Information and
        Verification) require as a condition of receiving housing assistance
        that you must sign a HUD-approved release and consent authorizing any
        depository or private source of income to furnish such information that
        is necessary in determining your eligibility or level of benefits. This
        includes information that you have provided which will affect the amount
        of rent you pay. The information includes income and assets, such as
        salary, welfare benefits, and interest earned on savings accounts. They
        also include certain adjustments to your income, such as the allowances
        for dependents and for households whose heads or spouses are elderly
        handicapped, or disabled; and allowances for child care expenses,
        medical expenses, and handicap assistance expenses.
      </p>
      <h3>Purpose of Requiring Consent to the Release of Information</h3>
      <p>
        In signing this consent form, you are authorizing the Owner of the
        housing project to which you are applying for assistance to request
        information from a third party about you. HUD requires the housing owner
        to verify all of the information you provide that affects your
        eligibility and level of benefits to ensure that you are eligible for
        assisted housing benefits and that these benefits are set at the correct
        levels. Upon the request of the HUD office or the PHA (as Contract
        Administrator), the housing Owner may provide HUD or the PHA with the
        information you have submitted and the information the Owner receives
        under this consent.
      </p>
      <h3>Uses of Information to be Obtained</h3>
      <p>
        The individual listed on the verification form may request and receive
        the information requested by the verification, subject to the
        limitations of this form. HUD is required to protect the income
        information it obtains in accordance with the Privacy Act of 1974, 5
        U.S.C. 552a. The Owner and the PHA are also required to protect the
        income information they obtain in accordance with any applicable state
        privacy law. Should the Owner receive information from a third party
        that is inconsistent with the information you have provided, the Owner
        is required to notify you in writing identifying the information
        believed to be incorrect. If this should occur, you will have the
        opportunity to meet with the Owner to discuss any discrepancies.
      </p>
      <h3>Who Must Sign the Consent Form</h3>
      <p>
        Each member of your household who is at least 18 years of age, and each
        family head, spouse or co-head, regardless of age must sign the relevant
        consent forms at the initial certification, at each recertification and
        at each interim certification, if applicable. In addition, when new
        adult members join the household and when members of the household
        become 18 years of age they must also sign the relevant consent forms.
      </p>
      <p>
        Persons who apply for or receive assistance under the following programs
        must sign the relevant consent forms:
      </p>
      <ul>
        <li>Rental Assistance Program (RAP)</li>
        <li>Rent Supplement</li>
        <li>
          Section 8 Housing Assistance Payments Programs (administered by the
          Office of Housing)
        </li>
        <li>Section 202</li>
        <li>Sections 202 and 811 PRAC</li>
        <li>Section 202/162 PAC</li>
        <li>Section 221(d)(3) Below Market Interest Rate</li>
        <li>Section 236</li>
        <li>HOPE 2 Home Ownership of Multifamily Units</li>
      </ul>
      <h3>Failure to Sign the Consent Form</h3>
      <p>
        Failure to sign any required consent form may result in the denial of
        assistance or termination of assisted housing benefits. If an applicant
        is denied assistance for this reason, the O/A must follow the
        notification procedures in Handbook 4350.3 Rev. 1. If a tenant is denied
        assistance for this reason, the O/A must follow the procedures set out
        in the lease.
      </p>
      <h3>Consent:</h3>
      <p>
        I consent to allow HUD, the O/A, or the PHA to request and obtain income
        information from the federal and state agencies listed on the back of
        this form for the purpose of verifying my eligibility and level of
        benefits under HUD's assisted housing programs.
      </p>
      <h3>Conditions</h3>
      <p>
        No action can be taken to terminate, deny, suspend or reduce the
        assistance your household receives based on information obtained about
        you under this consent until the O/A has independently 1) verified the
        information you have provided with respect to your eligibility and level
        of benefits and 2) with respect to income (including both earned and
        unearned income), the O/A has verified whether you actually have (or
        had) access to such income for your own use, and verified the period or
        periods when, or with respect to which you actually received such
        income, wages, or benefits.
      </p>
      <p>
        A photocopy of the signed consent may be used to request the information
        authorized by your signature on the individual consent forms. This would
        occur if the O/A does not have another individual verification consent
        with an original signature and the O/A is required to send out another
        request for verification (for example, the third party fails to
        respond). If this happens, the O/A may attach a photocopy of this
        consent to a photocopy of the individual verification form that you
        sign. To avoid the use of photocopies, the O/A and the individual may
        agree to sign more than one consent for each type of verification that
        is needed. The O/A shall inform you, or a third party which you
        designate, of the findings made on the basis of information verified
        under this consent and shall give you an opportunity to contest such
        findings in accordance with Handbook 4350.3 Rev. 1. The O/A must provide
        you with information obtained under this consent in accordance with
        State privacy laws.
      </p>
      <p>
        If a member of the household who is required to sign the consent forms
        is unable to sign the required forms on time, due to extenuating
        circumstances, the O/A may document the file as to the reason for the
        delay and the specific plans to obtain the proper signature as soon as
        possible. Individual consents to the release of information expire 15
        months after they are signed. The O/A may use these individual consent
        forms during the 120 days preceding the certification period. The O/A
        may also use these forms during the certification period, but only in
        cases where the O/A receives information indicating that the information
        you have provided may be incorrect. Other uses are prohibited.
      </p>
      <p>
        The O/A may not make inquiries into information that is older than 12
        months unless he/she has received inconsistent information and has
        reason to believe that the information that you have supplied is
        incorrect. If this occurs, the O/A may obtain information within the
        last 5 years when you have received assistance.
      </p>
      <h3>Penalties for Misusing this Consent:</h3>
      <p>
        HUD, the O/A, and any PHA (or any employee of HUD, the O/A, or the PHA)
        may be subject to penalties for unauthorized disclosures or improper
        uses of information collected based on the consent form. Use of the
        information collected based on form HUD 9887-A is restricted to the
        purposes cited on form HUD 9887-A. Any person who knowingly or willfully
        requests, obtains, or discloses any information under false pretenses
        concerning an applicant or tenant may be subject to a misdemeanor and
        fined not more than $5,000. Any applicant or tenant affected by
        negligent disclosure of information may bring a civil action for
        damages, and seek other relief, as may be appropriate, against the
        officer or employee of HUD, the O/A, or the PHA responsible for the
        unauthorized disclosure or improper use.
      </p>
      <p>
        I have read and understand this information on the purposes and uses of
        information that is verified and consent to the release of information
        for these purposes and uses. I have read and understand the purpose of
        this consent and its uses, and I understand that misuse of this consent
        can lead to personal penalties to me.
      </p>
    </>
  )
}

ConsentDocument.propTypes = exact(propTypes)
ConsentDocument.defaultProps = defaultProps

export default React.memo(ConsentDocument)
