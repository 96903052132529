import { values } from 'lodash'

function formatHouseholdIncomes(groupedIncomes, files) {
  values(groupedIncomes).forEach((incomesGroup) => {
    incomesGroup.forEach((income) => {
      // Match files to their corresponding income
      const filteredFiles = files.filter(
        (file) => file.firstPublishLocationId === income.sfid
      )
      income.files = filteredFiles
    })
  })
  return groupedIncomes
}

export default formatHouseholdIncomes
