import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { InputLabel } from 'lp-components'
import { Input, PhoneInput, Select, Checkbox, OptionalLabel } from 'components'
import { StepForm } from 'forms'
import { US_STATES, EMERGENCY_CONTACT_RELATIONSHIPS, STEP } from 'config'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  tenant: Yup.object({
    mailingAddress1: Yup.string().required('Address Line 1 is required'),
    mailingAddress2: Yup.string(),
    mailingCity: Yup.string().required('City is required'),
    mailingState: Yup.string().required('State is required'),
    mailingZip: Yup.string().required('ZIP code is required'),
    physicalAddress1: Yup.string().when(['sameAddressToggle'], {
      is: (sameAddressToggle) => !sameAddressToggle,
      then: (schema) => schema.required('Address Line 1 is required'),
    }),
    physicalAddress2: Yup.string(),
    physicalCity: Yup.string().when(['sameAddressToggle'], {
      is: (sameAddressToggle) => !sameAddressToggle,
      then: (schema) => schema.required('City is required'),
    }),
    physicalState: Yup.string().when(['sameAddressToggle'], {
      is: (sameAddressToggle) => !sameAddressToggle,
      then: (schema) => schema.required('State is required'),
    }),
    physicalZip: Yup.string().when(['sameAddressToggle'], {
      is: (sameAddressToggle) => !sameAddressToggle,
      then: (schema) => schema.required('ZIP code is required'),
    }),
    email: Yup.string().email('Invalid email address'),
    phone: Yup.string(),
    emergencyContactFirstName: Yup.string(),
    emergencyContactLastName: Yup.string(),
    emergencyContactRelationship: Yup.string(),
    emergencyContactEmail: Yup.string().email('Invalid email address'),
    emergencyContactPhone: Yup.string(),
  }),
  sameAddressToggle: Yup.boolean(),
})

function ContactInformationForm({ onSubmit, initialValues }) {
  const { sameAddressToggle, email, phone } = initialValues.tenant
  const [physicalSameAsMailing, setPhysicalSameAsMailing] =
    useState(sameAddressToggle)
  const hasEmail = !!email
  const hasPhone = !!phone

  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      previousStep={STEP.HOUSEHOLD_MEMBERS}
    >
      <div className="form-section">
        <div className="heading-wrapper">
          <h2>Mailing Address</h2>
        </div>
        <div className="row">
          <div className="col-12">
            <Input
              name="tenant.mailingAddress1"
              label="Address Line 1"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Input
              name="tenant.mailingAddress2"
              label="Address Line 2"
              labelComponent={OptionalLabel}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input name="tenant.mailingCity" label="City" required />
          </div>
          <div className="col-6">
            <Select
              name="tenant.mailingState"
              label="State"
              placeholder="Select"
              options={US_STATES}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input name="tenant.mailingZip" label="ZIP Code" required />
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="heading-wrapper">
          <h2>Physical Address</h2>
        </div>
        <div className="row">
          <div className="col-12">
            <Checkbox
              value={physicalSameAsMailing}
              checked={physicalSameAsMailing}
              onChange={() => setPhysicalSameAsMailing(!physicalSameAsMailing)}
              name="tenant.sameAddressToggle"
              label="Physical Address Same as Mailing Address"
            />
          </div>
        </div>
        {!physicalSameAsMailing && (
          <>
            <div className="row">
              <div className="col-12">
                <Input
                  name="tenant.physicalAddress1"
                  label="Address Line 1"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  name="tenant.physicalAddress2"
                  label="Address Line 2"
                  labelComponent={OptionalLabel}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input name="tenant.physicalCity" label="City" required />
              </div>
              <div className="col-6">
                <Select
                  name="tenant.physicalState"
                  label="State"
                  placeholder="Select"
                  options={US_STATES}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input name="tenant.physicalZip" label="ZIP Code" required />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="form-section">
        <div className="heading-wrapper">
          <h2>Contact</h2>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              name="tenant.email"
              label="Email"
              labelComponent={hasEmail ? InputLabel : OptionalLabel}
              disabled={hasEmail}
            />
          </div>
          <div className="col-6">
            <PhoneInput
              name="tenant.phone"
              labelComponent={hasPhone ? InputLabel : OptionalLabel}
              disabled={hasPhone}
            />
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="heading-wrapper">
          <h2>Emergency Contact</h2>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              name="tenant.emergencyContactFirstname"
              label="Emergency Contact First Name"
              labelComponent={OptionalLabel}
            />
          </div>
          <div className="col-6">
            <Input
              name="tenant.emergencyContactLastname"
              label="Emergency Contact Last Name"
              labelComponent={OptionalLabel}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Select
              name="tenant.emergencyContactRelationship"
              label="Relationship"
              labelComponent={OptionalLabel}
              options={EMERGENCY_CONTACT_RELATIONSHIPS}
              placeholder="Select"
            />
          </div>
          <div className="col-6">
            <Input
              name="tenant.emergencyContactEmail"
              label="Email"
              labelComponent={OptionalLabel}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <PhoneInput
              name="tenant.emergencyContactPhone"
              labelComponent={OptionalLabel}
            />
          </div>
        </div>
      </div>
    </StepForm>
  )
}

ContactInformationForm.propTypes = exact(propTypes)
ContactInformationForm.defaultProps = defaultProps

export default React.memo(ContactInformationForm)
