import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FlashMessageContext } from 'contexts'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function FlashMessage({ children }) {
  const initialState = { isError: false, message: '' }
  const [showFlash, setShowFlash] = useState(false)
  const [flash, setFlash] = useState(initialState)
  const flashStatus = flash.isError ? 'failure' : 'success'

  useEffect(() => {
    if (flash.message) setShowFlash(true)
  }, [flash.message])

  useEffect(() => {
    if (showFlash) setTimeout(() => setShowFlash(false), 10000)
  }, [showFlash])

  return (
    <FlashMessageContext.Provider value={setFlash}>
      <div role="alert" aria-atomic className="flash-message-container">
        <CSSTransition
          in={showFlash}
          timeout={500}
          classNames="flash"
          onExited={() => setFlash(initialState)}
          unmountOnExit
        >
          <div className={classNames('flash-message', flashStatus)}>
            <p>
              <strong>{flash.message}</strong>
            </p>
          </div>
        </CSSTransition>
      </div>
      {children}
    </FlashMessageContext.Provider>
  )
}

FlashMessage.propTypes = exact(propTypes)
FlashMessage.defaultProps = defaultProps

export default React.memo(FlashMessage)
