// Build full path using provided relative path
function buildPath(path) {
  return window.location.origin + path
}

// Imitates the behavior of React Router's push() in vanilla JS
function push(path) {
  window.location = buildPath(path)
}

export default push
