import React, { useState } from 'react'
import * as Types from 'types'
import { documentIcon, viewIcon } from 'images'
import {
  stripPersonalVerificationPrefixFromFileName,
  useFlashMessages,
} from 'utils'
import { fetchFileDownloadDetails } from 'api'
import { Modal } from 'lp-components'
import { isEmpty } from 'lodash'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'

const propTypes = {
  file: Types.file,
}

const defaultProps = {
  file: null,
}

function DocumentPreview({ file }) {
  const [isDownloading, setIsDownloading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [fileDownloadDetails, setFileDownloadDetails] = useState({})
  const { showFlashFailure } = useFlashMessages()

  if (!file) return null

  const getFileDownloadDetails = async () => {
    setIsDownloading(true)
    setFileDownloadDetails({})
    try {
      setFileDownloadDetails(await fetchFileDownloadDetails(file.id))
      setShowModal(true)
    } catch (error) {
      showFlashFailure('Error fetching file url: ', error)
    }
    setIsDownloading(false)
  }

  const displayFileName = stripPersonalVerificationPrefixFromFileName(file.name)

  return (
    <>
      <div className="document-file">
        <div className="file-name">
          <img src={documentIcon} alt="" />
          <span>{displayFileName}</span>
        </div>
        {isDownloading ? (
          <span className="download-button-spinner spinner" />
        ) : (
          <button
            type="button"
            className="download-button"
            onClick={getFileDownloadDetails}
            aria-label={`View file ${displayFileName}`}
          >
            <img src={viewIcon} alt="" />
          </button>
        )}
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        className="document-link-modal"
      >
        <div className="document-link-modal-content">
          <h2>Document Link</h2>
          {isEmpty(fileDownloadDetails) ? (
            <p>There was an issue fetching the file url. Please try again.</p>
          ) : (
            <>
              <p>
                Click the link below to access your document. This link will
                expire in{' '}
                {formatDistanceToNowStrict(
                  parseISO(fileDownloadDetails.expiryDate),
                  { unit: 'hour' }
                )}
                . You will need to enter the password provided in order to open
                the document.
              </p>
              <dl>
                <dt>Password:</dt>
                <dd>{fileDownloadDetails.password}</dd>
              </dl>
              <a
                href={fileDownloadDetails.distributionPublicUrl}
                target="_blank"
                rel="noreferrer"
              >
                Open Document
              </a>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

DocumentPreview.propTypes = propTypes
DocumentPreview.defaultProps = defaultProps

export default React.memo(DocumentPreview)
