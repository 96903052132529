import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { IncomeSectionsAccordion } from 'components'

const propTypes = {
  householdMember: Types.householdMember.isRequired,
  fundingProgram: PropTypes.string.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(Types.file).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setMembers: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
}

const defaultProps = {}

function MemberIncomeAccordionItem({
  householdMember,
  fundingProgram,
  isLoading,
  files,
  setMembers,
  handleSubmitCrud,
  setSectionCompletionStatus,
}) {
  const heading = `${householdMember.firstName} ${householdMember.lastName}`
  return (
    <AccordionItem className="form-section" uuid={householdMember.id}>
      <div className="row">
        <AccordionItemHeading aria-level={2} className="col-12">
          <AccordionItemButton>{heading}</AccordionItemButton>
        </AccordionItemHeading>
      </div>
      <AccordionItemPanel>
        <IncomeSectionsAccordion
          householdMember={householdMember}
          fundingProgram={fundingProgram}
          isLoading={isLoading}
          files={files}
          setMembers={setMembers}
          handleSubmitCrud={handleSubmitCrud}
          setSectionCompletionStatus={setSectionCompletionStatus}
        />
      </AccordionItemPanel>
    </AccordionItem>
  )
}

MemberIncomeAccordionItem.propTypes = exact(propTypes)
MemberIncomeAccordionItem.defaultProps = defaultProps

export default React.memo(MemberIncomeAccordionItem)
