import React, { createRef, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { withJsonApiProps, withLayout, useStepFormSubmit } from 'utils'
import {
  ApplicationLayout,
  MultiFormButtons,
  FormAccordionItem,
} from 'components'
import { StudentStatusForm } from 'forms'
import { first, isEmpty } from 'lodash'
import { Accordion } from 'react-accessible-accordion'
import { STEP } from 'config'
import { updateHouseholdMember } from 'api'
import moment from 'moment'

const propTypes = {
  students: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function StudentStatus({ students }) {
  const { handleSubmitUpdate, setDisableSingleFormFlash } = useStepFormSubmit({
    updateRequest: updateHouseholdMember,
    successFlashMessage: 'Student Status saved successfully!',
  })
  const formRefsRef = useRef(students.map(createRef))
  const formRefs = formRefsRef.current

  const hasStudents = !isEmpty(students)

  const instructions = hasStudents
    ? 'Fill in the information for each of the students below.'
    : 'You may skip this step as there are no students in your household.'

  return (
    <>
      <Helmet>
        <title>Student Status</title>
      </Helmet>
      <div className="page-header">
        <h1>Student Status</h1>
        <p>{instructions}</p>
      </div>
      {hasStudents && (
        <Accordion
          preExpanded={[first(students).id]}
          allowZeroExpanded
          allowMultipleExpanded
        >
          {students.map((student, index) => {
            const heading = `${student.firstName} ${student.lastName}`
            const isAdultStudent =
              moment().diff(
                moment(student.dateOfBirth, 'MM/DD/YYYY'),
                'years'
              ) >= 18

            return (
              <FormAccordionItem
                key={student.id}
                heading={heading}
                uuid={student.id}
              >
                {(setShowValidationError) => (
                  <StudentStatusForm
                    initialValues={{ [student.id]: student }}
                    onSubmit={handleSubmitUpdate}
                    studentId={student.id}
                    isAdultStudent={isAdultStudent}
                    setShowValidationError={setShowValidationError}
                    formRef={formRefs[index]}
                  />
                )}
              </FormAccordionItem>
            )
          })}
        </Accordion>
      )}
      <MultiFormButtons
        formRefs={formRefs}
        previousStep={STEP.APPLICATION_INFORMATION}
        nextStep={STEP.HOUSEHOLD_INCOME}
        setDisableSingleFormFlash={setDisableSingleFormFlash}
        skipSubmit={!hasStudents}
      />
    </>
  )
}

StudentStatus.propTypes = propTypes
StudentStatus.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(StudentStatus)
