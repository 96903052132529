import React from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { updateTenantApplication } from 'api'
import {
  withJsonApiProps,
  withLayout,
  useStepFormSubmit,
  goToNextStep,
} from 'utils'
import { ApplicationLayout } from 'components'
import { STEP } from 'config'
import { ApplicationInformationForm } from 'forms'

const propTypes = {
  tenantApplication: Types.tenantApplication.isRequired,
  asset: Types.asset.isRequired,
  certType: PropTypes.string.isRequired,
}
const defaultProps = {}

function ApplicationInformation({ asset, tenantApplication, certType }) {
  const { handleSubmitUpdate } = useStepFormSubmit({
    updateRequest: updateTenantApplication,
  })
  return (
    <>
      <Helmet>
        <title>Application Information</title>
      </Helmet>
      <div className="page-header">
        <h1>Application Information</h1>
        <p>
          In this section, fill out any information about your housing
          preferences and current circumstances.
        </p>
      </div>

      <ApplicationInformationForm
        initialValues={{ tenantApplication }}
        asset={asset}
        certType={certType}
        onSubmit={async ({ tenantApplication }) => {
          const isSubmitSuccess = await handleSubmitUpdate({
            tenantApplication,
          })
          if (isSubmitSuccess) goToNextStep(STEP.STUDENT_STATUS)
        }}
      />
    </>
  )
}

ApplicationInformation.propTypes = propTypes
ApplicationInformation.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ApplicationInformation)
