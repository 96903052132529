import getMemberAgeInYears from './getMemberAgeInYears'

function checkRequirements({ requirements, fundingProgram, householdMember }) {
  if (!requirements) return true

  return requirements.every((requirement) => {
    if (requirement === 'fundingProgram') {
      return fundingProgram.includes('HUD') || fundingProgram === 'USDA'
    }
    if (requirement === 'disabledOr62') {
      const isUser62OrOlder = getMemberAgeInYears(householdMember) >= 62
      return householdMember.disabled || isUser62OrOlder
    }
    if (requirement === 'student') {
      return householdMember.student
    }
    return false
  })
}

function getIncomeQuestions({
  fundingProgram,
  householdMember,
  sectionQuestions,
}) {
  const questions = []

  sectionQuestions.forEach((question) => {
    const requirementsMet = checkRequirements({
      requirements: question.requirements,
      fundingProgram,
      householdMember,
    })
    if (requirementsMet) questions.push(question)
  })

  return questions
}

export default getIncomeQuestions
