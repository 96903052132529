import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { IncomeAccordionForm } from 'forms'
import { groupBy, every } from 'lodash'
import {
  createInitialIncomes,
  formatHouseholdIncomes,
  getIncomeQuestions,
  updateIncomeParams,
} from 'utils'
import { fetchHouseholdMembers, fetchFiles } from 'api'
import { checkmark } from 'images'
import classNames from 'classnames'

const propTypes = {
  stepNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: Types.householdIncomeSection.isRequired,
  uuid: PropTypes.string.isRequired,
  householdMember: Types.householdMember.isRequired,
  fundingProgram: PropTypes.string.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(Types.file).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setMembers: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
}

const defaultProps = {}

function IncomeSectionAccordionItem({
  stepNumber,
  title,
  data,
  uuid,
  householdMember,
  fundingProgram,
  files,
  isLoading,
  setMembers,
  handleSubmitCrud,
  setSectionCompletionStatus,
}) {
  const groupedIncomes = groupBy(householdMember.incomes, 'shortenedType')

  const [initialHouseholdIncomes, setInitialHouseholdIncomes] = useState(
    createInitialIncomes({
      fundingProgram,
      householdMember,
      existingIncomes: formatHouseholdIncomes(groupedIncomes, files),
    })
  )
  const questionTypesFormSection = data['questions'].map((q) => q.questionType)

  const filteredIncomes = Object.keys(initialHouseholdIncomes)
    .filter((key) => questionTypesFormSection.includes(key))
    .reduce((acc, key) => {
      acc[key] = initialHouseholdIncomes[key]
      return acc
    }, {})

  const applicableQuestions = getIncomeQuestions({
    fundingProgram,
    householdMember,
    sectionQuestions: data['questions'],
  })

  const isSectionInitiallyComplete = every(filteredIncomes, (incomes) => {
    return every(incomes, ({ applies, amount }) => {
      if (applies) return !!amount

      return applies === false
    })
  })

  const [isSectionComplete, setIsSectionComplete] = useState(
    isSectionInitiallyComplete
  )
  const sectionName = `${householdMember.id}_${title}`

  useEffect(() => {
    setSectionCompletionStatus(sectionName, isSectionComplete)
  }, [setSectionCompletionStatus, sectionName, isSectionComplete])

  return (
    <AccordionItem className="section-accordion-item" uuid={uuid}>
      <div className="row sub-section">
        <AccordionItemHeading
          aria-level={3}
          className="col-12 no-bottom-margin"
        >
          <AccordionItemButton>
            <div
              className={classNames('step-number-box', {
                complete: isSectionComplete,
              })}
            >
              {isSectionComplete ? (
                <img
                  className="checkmark"
                  src={checkmark}
                  alt={`${title} is complete for ${householdMember.firstName} ${householdMember.lastName}`}
                />
              ) : (
                stepNumber
              )}
            </div>
            {title}
          </AccordionItemButton>
        </AccordionItemHeading>
      </div>
      <AccordionItemPanel>
        <IncomeAccordionForm
          initialValues={{
            incomes: filteredIncomes,
          }}
          setSectionIncomplete={() => setIsSectionComplete(false)}
          onSubmit={async ({ incomes }) => {
            if (isLoading) return false

            const isSubmitSuccess = await handleSubmitCrud(
              incomes,
              filteredIncomes,
              updateIncomeParams
            )

            if (isSubmitSuccess) {
              const updatedHouseholdMembers = await fetchHouseholdMembers()

              setMembers(updatedHouseholdMembers)
              // especially when creating new incomes, need to get the updated incomes with their sfids from the household member so that we can add the files correctly.
              const currentMember = updatedHouseholdMembers.find(
                (member) => member.sfid === householdMember.sfid
              )

              const newGroupedIncomes = groupBy(
                currentMember.incomes,
                'shortenedType'
              )

              const incomeSfids = updatedHouseholdMembers.flatMap((member) =>
                member.incomes.map((income) => income.sfid)
              )
              const updatedFiles = await fetchFiles(incomeSfids)

              const updatedValues = formatHouseholdIncomes(
                newGroupedIncomes,
                updatedFiles
              )

              setIsSectionComplete(true)
              setInitialHouseholdIncomes(updatedValues)
            }
            return isSubmitSuccess
          }}
          householdMemberSfid={householdMember.sfid}
          sourceHeader={data['sourceHeader']}
          questions={applicableQuestions}
          isLoading={isLoading}
        />
      </AccordionItemPanel>
    </AccordionItem>
  )
}

IncomeSectionAccordionItem.propTypes = exact(propTypes)
IncomeSectionAccordionItem.defaultProps = defaultProps

export default React.memo(IncomeSectionAccordionItem)
