import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import classNames from 'classnames'
import { Button, ButtonArea } from 'lp-components'
import {
  withJsonApiProps,
  withLayout,
  goToNextStep,
  getPathByStepIndex,
  push,
  useOrderedSteps,
} from 'utils'
import { ApplicationLayout, ApplicationInstructions } from 'components'
import { welcomePageImage } from 'images'
import { STEP } from 'config'

const propTypes = {
  tenantCase: Types.tenantCase.isRequired,
  currentStep: PropTypes.string.isRequired,
  overviewVideoUrl: PropTypes.string.isRequired,
}
const defaultProps = {}

function WelcomePage({ overviewVideoUrl, tenantCase, currentStep }) {
  const { householdName } = tenantCase
  const applicationStarted = !!currentStep
  const [steps] = useOrderedSteps()
  const currentStepIndex = steps.findIndex((step) => step.name === currentStep)

  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <div className="welcome-page">
        <h1 className="welcome-page-title">
          Welcome{applicationStarted && ' Back'}
          {householdName && `, ${householdName}`}!
        </h1>
        <div className="info-container">
          <img alt="" className="welcome-image" src={welcomePageImage}></img>
          <div
            className={classNames('welcome-message', {
              started: applicationStarted,
            })}
          >
            <h2 className="welcome-message-title primary-color">
              {applicationStarted
                ? "You're about to pick up where you left off!"
                : "You're about to begin the housing application process."}
            </h2>
            <p className="welcome-message-paragraph">
              The step details are displayed over on the left. You don't have to
              complete this form in one sitting. If you need to fetch something
              or are just in need of a break, you can put it down and come back
              later.
            </p>
          </div>
        </div>
        {!applicationStarted && (
          <ApplicationInstructions overviewVideoUrl={overviewVideoUrl} />
        )}
        <ButtonArea>
          {applicationStarted ? (
            <Button
              onClick={() => push(getPathByStepIndex(steps, currentStepIndex))}
            >
              Resume Your Application
            </Button>
          ) : (
            <Button onClick={() => goToNextStep(STEP.HOUSEHOLD_MEMBERS)}>
              Begin Your Application
            </Button>
          )}
        </ButtonArea>
      </div>
    </>
  )
}

WelcomePage.propTypes = propTypes
WelcomePage.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(WelcomePage)
