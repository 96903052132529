import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { trashIcon } from 'images'
import {
  isFileSizeValid,
  useFlashMessages,
  stripPersonalVerificationPrefixFromFileName,
} from 'utils'
import { FILE_SIZE_TEXT } from 'config'

const propTypes = {
  file: Types.file.isRequired,
  onRemove: PropTypes.func.isRequired,
}

const defaultProps = {}

function DocumentRemove({ file, onRemove }) {
  const { showFlashFailure } = useFlashMessages()

  // If file size isn't valid, remove immediately
  useEffect(() => {
    if (!isFileSizeValid(file)) {
      showFlashFailure(FILE_SIZE_TEXT)
      onRemove()
    }
  }, [file, showFlashFailure, onRemove])

  return (
    <button
      type="button"
      aria-label={`Remove file ${stripPersonalVerificationPrefixFromFileName(
        file.name
      )}`}
      onClick={onRemove}
      className="document-remove-button"
    >
      <img src={trashIcon} alt="" />
    </button>
  )
}

DocumentRemove.propTypes = exact(propTypes)
DocumentRemove.defaultProps = defaultProps

export default React.memo(DocumentRemove)
