import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Header, Sidebar, FlashMessage } from 'components'
import classNames from 'classnames'
import { orderedStepsContext } from 'contexts'
import { MOBILE_WIDTH } from 'config'
import { push, getPathByStepIndex, getOrderedSteps } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  caseNumber: PropTypes.string,
  disableSidebar: PropTypes.bool,
  orderedSteps: PropTypes.arrayOf(PropTypes.string),
}
const defaultProps = {
  disableSidebar: false,
  caseNumber: null,
}

function ApplicationLayout({
  children,
  currentPath,
  currentStep,
  contactEmail,
  disableSidebar,
  caseNumber,
  orderedSteps,
}) {
  const steps = getOrderedSteps(orderedSteps)
  // currentStep is the name of the current step ie "Personal Information" and currentPath is the path the user is on ie "/personal-information"
  const currentPathIndex = steps.findIndex((step) => step.path === currentPath)
  const currentStepIndex = steps.findIndex((step) => step.name === currentStep)

  useEffect(() => {
    if (currentPathIndex > currentStepIndex) {
      push(getPathByStepIndex(steps, currentStepIndex))
    }
  }, [currentPathIndex, currentStepIndex, steps])

  const width = window.innerWidth
  const isMobile = width <= MOBILE_WIDTH
  const [sidebarVisible, setSidebarVisible] = useState(
    !isMobile && !disableSidebar
  )

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible)
  }

  return (
    <>
      {/* Provide ordered steps context to app */}
      <orderedStepsContext.Provider value={steps}>
        <div className="pos-relative">
          <Header
            {...{ toggleSidebar, sidebarVisible, contactEmail, disableSidebar }}
          />
          {sidebarVisible && (
            <Sidebar
              {...{ currentPath, toggleSidebar, currentStep, caseNumber }}
            />
          )}
          <div
            className={classNames('view-container', {
              'with-sidebar-opened': sidebarVisible,
            })}
          >
            <FlashMessage>
              <main className="main-block-container">{children}</main>
            </FlashMessage>
          </div>
        </div>
      </orderedStepsContext.Provider>
    </>
  )
}

ApplicationLayout.propTypes = propTypes
ApplicationLayout.defaultProps = defaultProps

export default memo(ApplicationLayout)
