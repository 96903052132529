import React, { createContext, useState } from 'react'

// Extension of createContext that returns a value and a setter
// https://github.com/streamich/react-use/issues/247

function createStateContext(initialValue) {
  const context = createContext([initialValue, () => {}])
  const InnerProvider = context.Provider
  function Provider({ value, ...rest }) {
    const state = useState(value || initialValue)
    return <InnerProvider value={state} {...rest} />
  }
  context.Provider = Provider
  return context
}

export default createStateContext
