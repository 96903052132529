import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { first, groupBy, isEmpty, keys } from 'lodash'
import { HOUSEHOLD_INCOME_SECTIONS } from 'config'
import { convertBoolToString, formatDollars } from 'utils'

const propTypes = {
  householdIncomes: PropTypes.arrayOf(Types.householdIncome).isRequired,
}

const defaultProps = {}

function ReviewHouseholdIncomes({ householdIncomes }) {
  if (isEmpty(householdIncomes)) {
    return <p>There are no members with income in your household.</p>
  }

  const incomesGroupedByMember = groupBy(
    householdIncomes,
    'householdMemberName'
  )
  const memberNames = keys(incomesGroupedByMember)

  return memberNames.map((memberName, index) => {
    const incomes = incomesGroupedByMember[memberName]
    const isLastMember = index === memberNames.length - 1
    return (
      <React.Fragment key={memberName}>
        <div>
          <h4>{memberName}</h4>
          {HOUSEHOLD_INCOME_SECTIONS.map(({ header, questions }) => (
            <div key={header}>
              <h5>{header}</h5>
              {questions.map(
                ({ questionType, label, sourceLabel, subQuestions = [] }) => {
                  const matchingIncomes = incomes.filter(
                    (income) => income.shortenedType === questionType
                  )
                  if (isEmpty(matchingIncomes)) return null

                  const incomeApplies = first(matchingIncomes).applies

                  return (
                    <dl key={questionType}>
                      <dt>{label}</dt>
                      <dd>{convertBoolToString(incomeApplies)}</dd>
                      {incomeApplies &&
                        matchingIncomes.map(
                          ({
                            id,
                            source,
                            amount,
                            frequency,
                            additionalDetails,
                            omitSource,
                          }) => (
                            <div key={id} className="income-details">
                              {!omitSource && (
                                <>
                                  <dt>{sourceLabel || 'Source of Income'}</dt>
                                  <dd>{source}</dd>
                                </>
                              )}
                              <dt>Amount</dt>
                              <dd>{formatDollars(amount)}</dd>
                              <dt>Frequency</dt>
                              <dd>{frequency}</dd>
                              {subQuestions.map(
                                ({ subQuestionType, label }) => {
                                  const matchingAnswer =
                                    additionalDetails[subQuestionType]
                                  if (!matchingAnswer) return null

                                  const matchingAnswerDetails =
                                    additionalDetails[
                                      `${subQuestionType}Details`
                                    ]
                                  return (
                                    <React.Fragment key={subQuestionType}>
                                      <dt>{label}</dt>
                                      <dd>{matchingAnswer}</dd>
                                      {matchingAnswerDetails && (
                                        <div className="income-details">
                                          <dt>Provide Details</dt>
                                          <dd>{matchingAnswerDetails}</dd>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                }
                              )}
                            </div>
                          )
                        )}
                    </dl>
                  )
                }
              )}
            </div>
          ))}
        </div>
        {!isLastMember && <div className="bottom-border" />}
      </React.Fragment>
    )
  })
}

ReviewHouseholdIncomes.propTypes = exact(propTypes)
ReviewHouseholdIncomes.defaultProps = defaultProps

export default React.memo(ReviewHouseholdIncomes)
