import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Form, Formik, FieldArray } from 'formik'
import { SubmitButton, ButtonArea } from 'lp-components'
import {
  RadioGroup,
  Input,
  Select,
  HouseholdIncomeSubQuestions,
  DocumentUploads,
  CurrencyInput,
} from 'components'
import {
  first,
  isArray,
  keys,
  omit,
  values,
  findIndex,
  filter,
  isEqual,
  flattenDeep,
  isEmpty,
  reduce,
} from 'lodash'
import * as Yup from 'yup'
import uuid from 'react-uuid'
import { createInitialIncomeAdditionalDetails } from 'utils'
import { destroyFiles } from 'api'
import {
  BOOLEAN_OPTIONS,
  FREQUENCY_OPTIONS,
  INITIAL_FORM_VALUES,
  HOUSEHOLD_INCOME_SECTIONS,
} from 'config'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  sourceHeader: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(Types.householdIncomeQuestion).isRequired,
  householdMemberSfid: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSectionIncomplete: PropTypes.func.isRequired,
}

const defaultProps = {}

async function handleFilesDelete(fileContentDocumentIds) {
  await destroyFiles(fileContentDocumentIds)
}

function removeFiles(formValuesIncomes) {
  const filesToRemove = formValuesIncomes.map((formIncome) => {
    return formIncome.files
  })
  const fileContentDocumentIds = flattenDeep(filesToRemove).map(
    (file) => file.contentDocumentId
  )
  if (!isEmpty(fileContentDocumentIds)) {
    handleFilesDelete(fileContentDocumentIds)
  }
}

function IncomeAccordionForm({
  onSubmit,
  initialValues,
  sourceHeader,
  questions,
  householdMemberSfid,
  isLoading,
  setSectionIncomplete,
}) {
  const omitValues = (values) => omit(values, ['key', 'type'])

  const cleanFormValues = (formValues) => {
    if (isArray(formValues)) {
      return formValues.map(omitValues)
    }
    return omitValues(formValues)
  }

  const beforeSubmit = (formParams) => {
    // Assumes only one key/value pair
    const formValues = first(values(formParams))
    const formKey = first(keys(formParams))
    return onSubmit({ [formKey]: cleanFormValues(formValues) })
  }

  const validationSchema = (isLoading) => {
    if (isLoading) return Yup.object().shape({})

    const incomeSchema = Yup.object().shape({
      applies: Yup.boolean().required('An answer is required'),
      source: Yup.string().when(['applies', 'shortenedType'], {
        is: (applies, sourceType) => applies && !shouldOmitSource(sourceType),
        then: (schema) => schema.required('Source is required'),
      }),
      frequency: Yup.string().when('applies', {
        is: true,
        then: (schema) => schema.required('Frequency is required'),
      }),
      amount: Yup.string().when('applies', {
        is: true,
        then: (schema) => schema.required('Amount is required'),
      }),
      additionalDetails: Yup.object().when(
        ['applies', 'shortenedType'],
        (applies, shortenedType) => {
          const subQuestions = getSubQuestions(shortenedType)
          if (applies && !isEmpty(subQuestions)) {
            const additionalDetails = subQuestions.reduce(
              (details, subQuestion) => {
                const subQuestionSchema = subQuestion.optional
                  ? Yup.string()
                  : Yup.string().required(`${subQuestion.label} is required`)

                const subQuestionType = subQuestion.subQuestionType

                let additionalDetailsSchema = {}
                if (subQuestion.displayType === 'additionalInformationField') {
                  additionalDetailsSchema[`${subQuestionType}Details`] =
                    Yup.string().when(subQuestionType, {
                      is: 'Yes',
                      then: () =>
                        Yup.string().required(
                          `${subQuestion.label} Details is required`
                        ),
                    })
                }

                return {
                  ...details,
                  [subQuestionType]: subQuestionSchema,
                  ...additionalDetailsSchema,
                }
              },
              {}
            )

            return Yup.object().shape(additionalDetails)
          }
        }
      ),
      files: Yup.array().when('applies', {
        is: true,
        then: (schema) =>
          schema.min(1, 'A file is required').required('A file is required'),
      }),
    })

    const getSubQuestions = (shortenedType) => {
      const subQuestions = []
      HOUSEHOLD_INCOME_SECTIONS.find((section) => {
        const matchingSubQuestions = section.questions.find(
          (question) => question.questionType === shortenedType
        )?.subQuestions
        if (matchingSubQuestions) subQuestions.push(...matchingSubQuestions)
        return !!matchingSubQuestions
      })

      return subQuestions
    }

    const shouldOmitSource = (shortenedType) =>
      HOUSEHOLD_INCOME_SECTIONS.some((section) =>
        section.questions.some(
          (question) =>
            question.questionType === shortenedType && question.omitSource
        )
      )

    const incomeSchemas = reduce(
      questions,
      (schema, question) => ({
        ...schema,
        [question.questionType]: Yup.array().of(incomeSchema),
      }),
      {}
    )

    return Yup.object().shape({
      incomes: Yup.object().shape(incomeSchemas),
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={beforeSubmit}
      validationSchema={() => Yup.lazy(() => validationSchema(isLoading))}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ isSubmitting, values, isValid, submitCount }) => (
        <Form noValidate>
          {questions.map((question, questionIndex) => {
            const {
              questionType,
              label,
              subQuestions,
              uploads,
              omitSource,
              sourceLabel,
              indentSection,
            } = question

            const formValuesIncomes = values.incomes[questionType] || []

            return (
              <FieldArray key={questionType} name={`incomes[${questionType}]`}>
                {({ push, remove }) => (
                  <div className="row bottom-border">
                    {formValuesIncomes.map((income, incomeIndex) => {
                      const questionNumber = questionIndex + 1
                      const showFields = first(formValuesIncomes).applies
                      const sourceHeaderLabel = `${sourceHeader} #${
                        incomeIndex + 1
                      }`
                      const showAddButton =
                        incomeIndex === formValuesIncomes.length - 1

                      /* if someone had answered "yes", created a bunch of incomes,
                      and then decided to go back and answer "no", we need to remove
                      all of the incomes with that same type field and update the
                      current value source fields to be empty strings. */
                      const handleOnChange = (event) => {
                        // if they choose No, need to make sure there aren't any files uploaded for that income
                        if (!event.target.value) {
                          // need to remove all files for any incomes with that shortenedType (not just the first one)
                          removeFiles(formValuesIncomes)
                        }

                        income.amount = ''
                        income.source = ''
                        income.additionalDetails =
                          createInitialIncomeAdditionalDetails(subQuestions)
                        income.frequency = ''

                        const removeIncomes = filter(
                          formValuesIncomes,
                          (obj) => !isEqual(obj, income)
                        )
                        removeIncomes.map((incomeToRemove) => {
                          const index = findIndex(
                            formValuesIncomes,
                            incomeToRemove
                          )
                          remove(index)
                          removeFiles(formValuesIncomes)
                        })
                      }

                      return (
                        <React.Fragment key={income.id ?? income.key}>
                          {incomeIndex < 1 && (
                            <div className="col-12">
                              <RadioGroup
                                name={`incomes[${questionType}][${incomeIndex}].applies`}
                                label={`${questionNumber}. ${label}`}
                                options={BOOLEAN_OPTIONS}
                                required
                                onChange={handleOnChange}
                              />
                            </div>
                          )}

                          {showFields && (
                            <div className="row sub-section sub-questions">
                              <div className="heading-wrapper">
                                <h5>{sourceHeaderLabel}</h5>
                                {incomeIndex > 0 && (
                                  <button
                                    type="button"
                                    className="link-warn remove-icon"
                                    onClick={() => remove(incomeIndex)}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                              {!omitSource && (
                                <div className="row">
                                  <div className="col-12">
                                    <Input
                                      name={`incomes[${questionType}][${incomeIndex}].source`}
                                      label={sourceLabel || 'Source of Income'}
                                      required
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-6">
                                  <CurrencyInput
                                    name={`incomes[${questionType}][${incomeIndex}].amount`}
                                    required
                                  />
                                </div>
                                <div className="col-6">
                                  <Select
                                    name={`incomes[${questionType}][${incomeIndex}].frequency`}
                                    label="Frequency"
                                    placeholder="Select"
                                    options={FREQUENCY_OPTIONS}
                                    required
                                  />
                                </div>
                              </div>
                              {subQuestions && (
                                <HouseholdIncomeSubQuestions
                                  subQuestions={subQuestions}
                                  name={`incomes[${questionType}][${incomeIndex}].additionalDetails`}
                                  indentSection={indentSection}
                                  value={
                                    formValuesIncomes[incomeIndex]
                                      .additionalDetails
                                  }
                                />
                              )}
                              {uploads && (
                                <div className="row">
                                  <div className="col-12 document-section">
                                    <DocumentUploads
                                      uploadDetails={uploads}
                                      name={`incomes[${questionType}][${incomeIndex}].files`}
                                      isLoading={isLoading}
                                    />
                                  </div>
                                </div>
                              )}
                              {showAddButton && (
                                <div className="row">
                                  <div className="col-12">
                                    <button
                                      type="button"
                                      className="link-primary add-icon add-button"
                                      onClick={() => {
                                        push({
                                          ...INITIAL_FORM_VALUES.HOUSEHOLD_INCOMES,
                                          key: uuid(),
                                          householdMemberSfid,
                                          shortenedType: questionType,
                                          applies: true,
                                        })
                                        setSectionIncomplete()
                                      }}
                                    >
                                      Add Another Source
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
              </FieldArray>
            )
          })}
          {!isValid && submitCount > 0 && (
            <div className="row form-wide-error">
              <div className="col-12">
                <span className="error-message">
                  Please review and resolve the errors on this form and
                  resubmit.
                </span>
              </div>
            </div>
          )}
          <ButtonArea>
            <SubmitButton submitting={isSubmitting}>Save</SubmitButton>
          </ButtonArea>
        </Form>
      )}
    </Formik>
  )
}

IncomeAccordionForm.propTypes = propTypes
IncomeAccordionForm.defaultProps = defaultProps

export default React.memo(IncomeAccordionForm)
