import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { StepForm, HouseholdMembersFormFields } from 'forms'
import { INITIAL_FORM_VALUES } from 'config'
import { FieldArray } from 'formik'
import uuid from 'react-uuid'
import { parse } from 'date-fns'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  householdMembers: Yup.array().of(
    Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      relationship: Yup.string().required('Relationship is required'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'You must be at least one day old')
        .required('Date of birth is required')
        .min(new Date('01/01/1900'), 'Invalid date')
        .transform((_, origValue) => {
          return parse(origValue, 'MM/dd/yyyy', new Date())
        }),
      student: Yup.boolean().required('Student status is required'),
      disabled: Yup.boolean().required('Disability status is required'),
      ssn: Yup.string()
        .matches(/^\d+$/, 'SSN must be valid')
        .length(9, 'SSN must be valid')
        .required('SSN is required'),
      income: Yup.boolean().required('Income status is required'),
      race: Yup.string().required('Race is required'),
      ethnicity: Yup.string().required('Ethnicity is required'),
      gender: Yup.string().required('Gender is required'),
      eligibleCitizen: Yup.string().required('Citizenship status is required'),
      eligibleCitizenDetails: Yup.string().when('eligibleCitizen', {
        is: (value) => value !== 'Yes', // when maritalStatus is not 'Single'
        then: () =>
          Yup.string().required('Eligible citizen details are required'), // make maritalStatusDetails required
      }),
      livedInOtherStates: Yup.string().required(
        'Lived in other states is required'
      ),
      livedInOtherStatesDetails: Yup.string().when('livedInOtherStates', {
        is: (value) => value == 'Yes',
        then: () =>
          Yup.string().required('Lived in other states details are required'),
      }),
      maritalStatus: Yup.string().required('Marital status is required'),
      maritalStatusDetails: Yup.string().when('maritalStatus', {
        is: (value) => value !== 'Single', // when maritalStatus is not 'Single'
        then: () =>
          Yup.string().required('Marital status details are required'), // make maritalStatusDetails required
      }),
    })
  ),
})

function HouseholdMembersForm({ onSubmit, initialValues }) {
  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      hidePreviousButton
    >
      {({ values, setFieldValue }) => (
        <FieldArray name="householdMembers">
          {({ push, remove }) => (
            <>
              {values.householdMembers.map(
                (
                  {
                    id,
                    key,
                    ssn,
                    maritalStatus,
                    eligibleCitizen,
                    livedInOtherStates,
                  },
                  index
                ) => {
                  const heading =
                    index > 0
                      ? `Person #${index + 1} Information`
                      : 'Your Information'
                  return (
                    <div key={id ?? key} className="form-section">
                      <div className="heading-wrapper">
                        <h2>{heading}</h2>
                        {index > 0 && (
                          <button
                            type="button"
                            className="link-warn remove-icon"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      <HouseholdMembersFormFields
                        index={index}
                        setFieldValue={setFieldValue}
                        currentSsnValue={ssn}
                        currentMaritalStatusValue={maritalStatus}
                        currentEligibleCitizenValue={eligibleCitizen}
                        currentLivedInOtherStatesValue={livedInOtherStates}
                      />
                    </div>
                  )
                }
              )}
              <button
                type="button"
                className="link-primary add-icon"
                onClick={() =>
                  push({
                    ...INITIAL_FORM_VALUES.HOUSEHOLD_MEMBERS,
                    key: uuid(),
                  })
                }
              >
                Add Another Person
              </button>
            </>
          )}
        </FieldArray>
      )}
    </StepForm>
  )
}

HouseholdMembersForm.propTypes = exact(propTypes)
HouseholdMembersForm.defaultProps = defaultProps

export default React.memo(HouseholdMembersForm)
