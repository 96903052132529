import { STEP } from 'config'

function getOrderedSteps(orderedStepNames) {
  if (!orderedStepNames) return []
  return orderedStepNames.map((stepName) => {
    return Object.values(STEP).find(
      (stepObject) => stepObject.name === stepName
    )
  })
}

export default getOrderedSteps
