const getMemberAgeInYears = (member) => {
  const dateOfBirth = new Date(member.dateOfBirth)
  const ageInYears = Math.floor(
    (new Date() - dateOfBirth) / (365 * 24 * 60 * 60 * 1000)
  )

  return ageInYears
}

export default getMemberAgeInYears
