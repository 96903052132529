import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'

const propTypes = {
  tenant: Types.tenant.isRequired,
}

const defaultProps = {}

function ReviewContactInformation({ tenant }) {
  const formatPhone = (phone) => {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
  }

  return (
    <dl>
      {tenant.mailingAddress1 && (
        <>
          <dt>Mailing Address</dt>
          <dd>
            {tenant.mailingAddress1},
            {tenant.mailingAddress2 && ` ${tenant.mailingAddress2},`}{' '}
            {tenant.mailingCity}, {tenant.mailingState} {tenant.mailingZip}
          </dd>
        </>
      )}
      {tenant.physicalAddress1 && (
        <>
          <dt>Physical Address</dt>
          <dd>
            {tenant.physicalAddress1},
            {tenant.physicalAddress2 && ` ${tenant.physicalAddress2},`}{' '}
            {tenant.physicalCity}, {tenant.physicalState} {tenant.physicalZip}
          </dd>
        </>
      )}
      {tenant.email && (
        <>
          <dt>Contact Email</dt>
          <dd>{tenant.email}</dd>
        </>
      )}
      {tenant.phone && (
        <>
          <dt>Contact Telephone</dt>
          <dd>{formatPhone(tenant.phone)}</dd>
        </>
      )}
      {tenant.emergencyContactFirstname && (
        <>
          <dt>Emergency Contact First Name</dt>
          <dd>{tenant.emergencyContactFirstname}</dd>
        </>
      )}
      {tenant.emergencyContactLastname && (
        <>
          <dt>Emergency Contact Last Name</dt>
          <dd>{tenant.emergencyContactLastname}</dd>
        </>
      )}
      {tenant.emergencyContactRelationship && (
        <>
          <dt>Relationship</dt>
          <dd>{tenant.emergencyContactRelationship}</dd>
        </>
      )}
      {tenant.emergencyContactEmail && (
        <>
          <dt>Emergency Email</dt>
          <dd>{tenant.emergencyContactEmail}</dd>
        </>
      )}
      {tenant.emergencyContactPhone && (
        <>
          <dt>Emergency Telephone</dt>
          <dd>{formatPhone(tenant.emergencyContactPhone)}</dd>
        </>
      )}
    </dl>
  )
}

ReviewContactInformation.propTypes = exact(propTypes)
ReviewContactInformation.defaultProps = defaultProps

export default React.memo(ReviewContactInformation)
