import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { every, isEmpty } from 'lodash'
import {
  withJsonApiProps,
  withLayout,
  useStepFormSubmit,
  processIncomeFiles,
  useFlashMessages,
  push,
  goToNextStep,
  anyAdultMembersWithoutIncome,
} from 'utils'
import { Accordion } from 'react-accessible-accordion'
import { ButtonArea, Button } from 'lp-components'
import { ApplicationLayout, MemberIncomeAccordionItem } from 'components'
import { STEP, DOCUMENTS_ERROR_MESSAGE } from 'config'
import {
  createHouseholdIncomes,
  updateHouseholdIncomes,
  deleteHouseholdIncome,
  fetchFiles,
} from 'api'

const propTypes = {
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
  fundingProgram: PropTypes.string.isRequired,
}

const defaultProps = {}

function HouseholdIncomes({ householdMembers, fundingProgram }) {
  const { handleSubmitCrud } = useStepFormSubmit({
    createRequest: createHouseholdIncomes,
    updateRequest: updateHouseholdIncomes,
    deleteRequest: deleteHouseholdIncome,
    filesProcessor: processIncomeFiles,
    successFlashMessage:
      'Household Income section saved successfully! You may move on to the next section.',
  })
  const [members, setMembers] = useState(householdMembers)
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [completionStatusForSections, setCompletionStatusForSections] =
    useState({})

  const setSectionCompletionStatus = useCallback(
    (sectionName, isSectionComplete) => {
      setCompletionStatusForSections((currentValue) => ({
        ...currentValue,
        [sectionName]: isSectionComplete,
      }))
    },
    []
  )

  const { showFlashFailure } = useFlashMessages()

  const hasHouseholdMembers = !isEmpty(members)
  const instructions = hasHouseholdMembers
    ? 'For each household member below, fill in their income information. This will include the name of the source of income, amount, frequency, and copies of paystubs or other verification of income.'
    : 'You may skip this step as there are no members of your household that earn income or are over the age of 18.'

  const incomeSfids = useMemo(() => {
    return members.flatMap((member) =>
      member.incomes.map((income) => income.sfid)
    )
  }, [members])

  const fetchAllFiles = useCallback(async () => {
    setIsLoading(true)
    try {
      const incomeFiles = await fetchFiles(incomeSfids)
      setIsLoading(false)
      return incomeFiles
    } catch {
      showFlashFailure(DOCUMENTS_ERROR_MESSAGE)
      setIsLoading(false)
      return false
    }
  }, [incomeSfids, showFlashFailure])

  const fetchAndSetFiles = useCallback(async () => {
    const incomeFiles = await fetchAllFiles()
    setFiles(incomeFiles)
  }, [fetchAllFiles])

  useEffect(() => {
    fetchAndSetFiles()
  }, [fetchAndSetFiles])

  const isFormComplete = every(
    completionStatusForSections,
    (isSectionComplete) => isSectionComplete
  )

  return (
    <>
      <Helmet>
        <title>Household Income</title>
      </Helmet>
      <div className="page-header">
        <h1>Household Income</h1>
        <p>{instructions}</p>
      </div>
      {hasHouseholdMembers && (
        <Accordion
          preExpanded={members.map(({ id }) => id)}
          allowZeroExpanded
          allowMultipleExpanded
        >
          {members.map((householdMember) => (
            <MemberIncomeAccordionItem
              key={householdMember.id}
              householdMember={householdMember}
              fundingProgram={fundingProgram}
              isLoading={isLoading}
              files={files}
              setMembers={setMembers}
              handleSubmitCrud={handleSubmitCrud}
              setSectionCompletionStatus={setSectionCompletionStatus}
            />
          ))}
        </Accordion>
      )}
      <p className="continue-warning">
        Please confirm that you've saved any changes made before continuing.
      </p>
      <ButtonArea>
        <Button
          className="button-primary-outline"
          onClick={() => push(STEP.STUDENT_STATUS.path)}
        >
          Previous Step
        </Button>
        <Button
          onClick={async () => {
            if (!isFormComplete) {
              return showFlashFailure(
                'Please complete all income form sections for every household member before continuing.'
              )
            }
            if (anyAdultMembersWithoutIncome(members)) {
              goToNextStep(STEP.ZERO_INCOME)
            } else {
              goToNextStep(STEP.HOUSEHOLD_ASSETS)
            }
          }}
        >
          Continue
        </Button>
      </ButtonArea>
    </>
  )
}

HouseholdIncomes.propTypes = propTypes
HouseholdIncomes.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(HouseholdIncomes)
