import { mapKeys, camelCase } from 'lodash'

function convertToCamelCase(data) {
  if (Array.isArray(data)) {
    // If the input is an array, map over each element and convert all keys to camelCase recursively
    const camelCaseData = data.map((item) => convertToCamelCase(item))
    return camelCaseData
  } else if (data !== null && typeof data === 'object') {
    // If the input is an object, convert all keys to camelCase
    const newObj = mapKeys(data, (_, key) => camelCase(key))
    // Recursively convert keys to camelCase for all nested objects and arrays
    for (const [key, value] of Object.entries(newObj)) {
      if (Array.isArray(value)) {
        newObj[key] = value.map((item) => convertToCamelCase(item))
      } else if (value !== null && typeof value === 'object') {
        newObj[key] = convertToCamelCase(value)
      }
    }
    return newObj
  }
  return data
}

export default convertToCamelCase
