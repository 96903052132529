import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { INITIAL_FORM_VALUES, STEP } from 'config'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  goToNextStep,
  useStepFormSubmit,
  updateHouseholdAssetsParams,
  anyAdultMembersWithoutIncome,
} from 'utils'
import { ApplicationLayout } from 'components'
import { HouseholdAssetsForm } from 'forms'
import { isEmpty } from 'lodash'
import { createHouseholdAssets, updateHouseholdAssets } from 'api'

const propTypes = {
  householdAssets: PropTypes.arrayOf(Types.householdAsset).isRequired,
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function HouseholdAssets({ householdAssets, householdMembers }) {
  const { handleSubmitCrud } = useStepFormSubmit({
    createRequest: createHouseholdAssets,
    updateRequest: updateHouseholdAssets,
  })

  const initialHouseholdAssets = isEmpty(householdAssets)
    ? INITIAL_FORM_VALUES.HOUSEHOLD_ASSETS
    : householdAssets

  return (
    <>
      <Helmet>
        <title>Household Assets</title>
      </Helmet>
      <div className="page-header">
        <h1>Household Assets</h1>
        <p>
          In this section you will fill out the asset information for your
          household as a whole.
        </p>
      </div>
      <HouseholdAssetsForm
        initialValues={{
          householdAssets: initialHouseholdAssets,
        }}
        householdMembers={householdMembers}
        onSubmit={async ({ householdAssets }) => {
          const updatedHouseholdAssets =
            updateHouseholdAssetsParams(householdAssets)
          const isSubmitSuccess = await handleSubmitCrud(
            updatedHouseholdAssets,
            initialHouseholdAssets
          )
          if (isSubmitSuccess) goToNextStep(STEP.PERSONAL_VERIFICATION)
        }}
        previousStep={
          anyAdultMembersWithoutIncome(householdMembers)
            ? STEP.ZERO_INCOME
            : STEP.HOUSEHOLD_INCOME
        }
      />
    </>
  )
}

HouseholdAssets.propTypes = propTypes
HouseholdAssets.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(HouseholdAssets)
