import { MAX_FILE_SIZE_MB } from 'config'

const isFileSizeValid = (file) => {
  if (!file) return
  // already uploaded files don't have a size
  if (!file.size) return true

  const fileSizeMB = file.size / (1024 * 1024)
  return fileSizeMB <= MAX_FILE_SIZE_MB
}

export default isFileSizeValid
