import { useContext } from 'react'
import { orderedStepsContext } from 'contexts'

// An easy way to access the ordered steps context

function useOrderedSteps() {
  return useContext(orderedStepsContext)
}

export default useOrderedSteps
