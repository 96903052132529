import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withJsonApiProps, withLayout, push, useFlashMessages } from 'utils'
import { ApplicationLayout } from 'components'
import { LoginForm } from 'forms'
import { login } from 'api'
import { loginLogo } from 'images'
import { APPLICATION_OVERVIEW_PATH } from 'config'

const propTypes = {
  caseNumber: PropTypes.string.isRequired,
}

const defaultProps = {}

function Login({ caseNumber }) {
  const { showFlashFailure } = useFlashMessages()

  return (
    <>
      <div className="login-background" />
      <div className="login-container">
        <img className="login-logo" src={loginLogo} alt="" />
        <h1 className="login-title">Log In</h1>
        <LoginForm
          initialValues={{
            caseNumber: caseNumber,
            email: '',
            phoneNumber: '',
          }}
          onSubmit={async (params) => {
            try {
              await login(params)
              push(APPLICATION_OVERVIEW_PATH)
            } catch (error) {
              showFlashFailure(error.response.data.message)
            }
          }}
        ></LoginForm>
      </div>
    </>
  )
}

Login.propTypes = propTypes
Login.defaultProps = defaultProps

export default compose(withJsonApiProps(), withLayout(ApplicationLayout))(Login)
