import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Accordion } from 'react-accessible-accordion'
import { ZeroIncomeSectionAccordionItem } from 'components'
import { kebabCase } from 'lodash'

const propTypes = {
  householdMember: Types.householdMember.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
  zeroIncomeHouseholdMemberSections: PropTypes.array.isRequired,
}

const defaultProps = {}

function ZeroIncomeSectionsAccordion({
  householdMember,
  handleSubmitCrud,
  setSectionCompletionStatus,
  zeroIncomeHouseholdMemberSections,
}) {
  return (
    <Accordion className="sections-accordion" allowZeroExpanded>
      {zeroIncomeHouseholdMemberSections.map((section, index) => (
        <ZeroIncomeSectionAccordionItem
          key={section.header}
          stepNumber={String(index + 1).padStart(2, '0')}
          title={section.header}
          data={section}
          uuid={`${kebabCase(section.header)}-${householdMember.id}`}
          householdMember={householdMember}
          handleSubmitCrud={handleSubmitCrud}
          setSectionCompletionStatus={setSectionCompletionStatus}
        />
      ))}
    </Accordion>
  )
}

ZeroIncomeSectionsAccordion.propTypes = exact(propTypes)
ZeroIncomeSectionsAccordion.defaultProps = defaultProps

export default React.memo(ZeroIncomeSectionsAccordion)
