import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { Input, MaskedInput, OptionalLabel } from 'components'
import { StepForm } from 'forms'
import { STEP } from 'config'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: Types.tenantApplication.isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  tenantApplication: Yup.object({
    assistanceProviderDate: Yup.date().test(
      'valid-year',
      'Year must be valid and in YYYY format',
      (value, { originalValue }) => {
        if (!value) return true

        const year = originalValue.split('/')[2]
        return /^[1-9]\d{3}$/.test(year)
      }
    ),
    assistanceProviderName: Yup.string(),
  }),
})

function AssistanceForm({ onSubmit, initialValues, children }) {
  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      previousStep={STEP.PERSONAL_VERIFICATION}
    >
      <div className="form-section">
        <div className="heading-wrapper">
          <h2>Assistance Tracking</h2>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              If you received any assistance while filling out this form, please
              record their name and the date below.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              name="tenantApplication.assistanceProviderName"
              label="Assistance Provided By"
              labelComponent={OptionalLabel}
            />
          </div>
          <div className="col-6">
            <MaskedInput
              name="tenantApplication.assistanceProviderDate"
              label="Date"
              labelComponent={OptionalLabel}
              placeholder="MM/DD/YYYY"
              maskOptions={{
                date: true,
                datePattern: ['m', 'd', 'Y'],
              }}
            />
          </div>
        </div>
      </div>
      {children}
    </StepForm>
  )
}

AssistanceForm.propTypes = exact(propTypes)
AssistanceForm.defaultProps = defaultProps

export default React.memo(AssistanceForm)
