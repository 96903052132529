import React from 'react'
// import PropTypes from 'prop-types'
import * as Types from 'types'
import { STEP } from 'config'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  goToNextStep,
  useStepFormSubmit,
  updateTenantParams,
  areTenantAddressesEqual,
} from 'utils'
import { ApplicationLayout } from 'components'
import { ContactInformationForm } from 'forms'
import { updateTenant } from 'api'

const propTypes = {
  tenant: Types.tenant.isRequired,
}

const defaultProps = {}

function ContactInformation({ tenant }) {
  const { handleSubmitUpdate } = useStepFormSubmit({
    updateRequest: updateTenant,
  })

  return (
    <>
      <Helmet>
        <title>Contact Information</title>
      </Helmet>
      <div className="page-header">
        <h1>Contact Information</h1>
        <p>
          In this section, enter your contact information as well as the
          information of a potential emergency contact.
        </p>
      </div>
      <ContactInformationForm
        initialValues={{
          tenant: {
            ...tenant,
            sameAddressToggle: areTenantAddressesEqual(tenant),
          },
        }}
        onSubmit={async ({ tenant }) => {
          const updatedTenant = updateTenantParams(tenant)
          const isSubmitSuccess = await handleSubmitUpdate({
            tenant: updatedTenant,
          })
          if (isSubmitSuccess) goToNextStep(STEP.APPLICATION_INFORMATION)
        }}
      />
    </>
  )
}

ContactInformation.propTypes = propTypes
ContactInformation.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ContactInformation)
