function updateHouseholdAssetsParams(householdAssets) {
  householdAssets.map((householdAsset, index) => {
    if (!householdAsset.applies) {
      householdAssets[index].source = null
      householdAssets[index].amount = null
      householdAssets[index].income = null
    }
  })
  return householdAssets
}

export default updateHouseholdAssetsParams
