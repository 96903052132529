import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { StepForm } from 'forms'
import { US_STATES, STUDENT_ADDITIONAL_QUESTIONS } from 'config'
import StudentStatusFormFields from './StudentStatusFormFields'
import { reduce } from 'lodash'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(Types.householdMember.isRequired)
    .isRequired,
  studentId: PropTypes.number.isRequired,
  setShowValidationError: PropTypes.func.isRequired,
  formRef: PropTypes.object.isRequired,
  isAdultStudent: PropTypes.bool.isRequired,
}

const defaultProps = {}

function StudentStatusForm({
  onSubmit,
  initialValues,
  studentId,
  isAdultStudent,
  setShowValidationError,
  formRef,
}) {
  const additionalQuestionsValidationSchema = isAdultStudent
    ? reduce(
        STUDENT_ADDITIONAL_QUESTIONS,
        (schema, question) => {
          schema[question.name] = Yup.string().required(
            'Additional question is required'
          )
          return schema
        },
        {}
      )
    : {}

  const validationSchema = Yup.object({
    [studentId]: Yup.object({
      studentEnrollment: Yup.string().required('Full or Part-Time is required'),
      studentInstitutionName: isAdultStudent
        ? Yup.string().required('Institution Name is required')
        : Yup.string(),
      studentInstitutionAddress1: isAdultStudent
        ? Yup.string().required('Institution Address is required')
        : Yup.string(),
      studentInstitutionAddress2: Yup.string(),
      studentInstitutionCity: isAdultStudent
        ? Yup.string().required('Institution City is required')
        : Yup.string(),
      studentInstitutionState: isAdultStudent
        ? Yup.string()
            .oneOf(US_STATES)
            .required('Institution State is required')
        : Yup.string().oneOf(US_STATES),
      studentInstitutionZip: isAdultStudent
        ? Yup.string()
            .matches(/^[0-9]+$/, 'ZIP Code must be valid')
            .length(5, 'ZIP Code must be valid')
            .required('ZIP Code is required')
        : Yup.string()
            .matches(/^[0-9]+$/, 'ZIP Code must be valid')
            .length(5, 'ZIP Code must be valid'),
      ...additionalQuestionsValidationSchema,
    }),
  })

  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      formRef={formRef}
      submitButtonText="Save"
      hidePreviousButton
    >
      {({ isValid }) => (
        <StudentStatusFormFields
          studentId={studentId}
          isAdultStudent={isAdultStudent}
          formIsValid={isValid}
          setShowValidationError={setShowValidationError}
        />
      )}
    </StepForm>
  )
}

StudentStatusForm.propTypes = exact(propTypes)
StudentStatusForm.defaultProps = defaultProps

export default React.memo(StudentStatusForm)
