import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { InputLabel } from 'lp-components'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

const defaultProps = {
  label: '',
}

function OptionalLabel({ name, label, ...rest }) {
  const labelText = label ?? startCase(name)

  return (
    <InputLabel {...{ name, label, ...rest }}>
      {labelText} <em className="optional">(Optional)</em>
    </InputLabel>
  )
}

OptionalLabel.propTypes = propTypes
OptionalLabel.defaultProps = defaultProps

export default React.memo(OptionalLabel)
