import React from 'react'
import { applicationComplete } from 'images'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { withJsonApiProps, withLayout } from 'utils'
import { ApplicationLayout } from 'components'

const propTypes = {}

const defaultProps = {}

function ApplicationCompleted() {
  return (
    <>
      <Helmet>
        <title>Application Overview - Completed</title>
      </Helmet>
      <div className="page-header">
        <h1>Application Overview</h1>
      </div>
      <div className="application-overview-card application-completed info-container">
        <div>
          <h2 className="primary-color">You've completed your application!</h2>
          <p className="bottom-separator">
            All that's left to do now is wait for approval. Someone from our
            team is going over the information you provided. You will be able to
            view your application's status below.
          </p>
          <div className="status-label">
            <span className="circle color-yellow" />
            <span>Application Under Review</span>
          </div>
        </div>
        <img alt="" src={applicationComplete} />
      </div>
    </>
  )
}

ApplicationCompleted.propTypes = propTypes
ApplicationCompleted.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ApplicationCompleted)
