import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { isEmpty } from 'lodash'

const propTypes = {
  students: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function ReviewStudentStatus({ students }) {
  if (isEmpty(students)) {
    return <p>There are no students in your household.</p>
  }

  return students.map((student, index) => {
    const isLastStudent = index === students.length - 1
    const {
      studentInstitutionName,
      studentInstitutionAddress1,
      studentInstitutionAddress2,
      studentInstitutionCity,
      studentInstitutionState,
      studentInstitutionZip,
    } = student
    const hasInstitutionInfo =
      !!studentInstitutionName ||
      !!studentInstitutionAddress1 ||
      !!studentInstitutionAddress2 ||
      !!studentInstitutionCity ||
      !!studentInstitutionState ||
      !!studentInstitutionZip
    return (
      <React.Fragment key={student.id}>
        <div>
          <h4>
            {student.firstName} {student.lastName}
          </h4>
          <dl>
            <dt>Full or Part-Time Student?</dt>
            <dd>{student.studentEnrollment}</dd>
            {hasInstitutionInfo && (
              <>
                <h5>Education Institution Information</h5>
                {studentInstitutionName && (
                  <>
                    <dt>Name of Institution</dt>
                    <dd>{studentInstitutionName}</dd>
                  </>
                )}
                {studentInstitutionAddress1 && (
                  <>
                    <dt>Address Line 1</dt>
                    <dd>{studentInstitutionAddress1}</dd>
                  </>
                )}
                {studentInstitutionAddress2 && (
                  <>
                    <dt>Address Line 2</dt>
                    <dd>{studentInstitutionAddress2}</dd>
                  </>
                )}
                {studentInstitutionCity && (
                  <>
                    <dt>City</dt>
                    <dd>{studentInstitutionCity}</dd>
                  </>
                )}
                {studentInstitutionState && (
                  <>
                    <dt>State</dt>
                    <dd>{studentInstitutionState}</dd>
                  </>
                )}
                {studentInstitutionZip && (
                  <>
                    <dt>ZIP Code</dt>
                    <dd>{studentInstitutionZip}</dd>
                  </>
                )}
              </>
            )}
          </dl>
        </div>
        {!isLastStudent && <div className="bottom-border" />}
      </React.Fragment>
    )
  })
}

ReviewStudentStatus.propTypes = exact(propTypes)
ReviewStudentStatus.defaultProps = defaultProps

export default React.memo(ReviewStudentStatus)
