import { flatten, values, isObject, reduce, omit } from 'lodash'

// remove the shortenedType keys and merge all of the arrays together into an incomes array.
// If there are additionalDetails, then concat into a key value string output to store to the database.
// Each key value pair will be separated by a semi-colon.
function updateIncomeParams(incomes) {
  const flattenedIncomes = flatten(values(incomes))

  const updatedIncomes = flattenedIncomes.map((income) => {
    const updatedIncome = { ...income }

    if (!income.applies) {
      // Clear out any initial additional details if income doesn't apply
      updatedIncome.additionalDetails = ''
    } else if (isObject(income.additionalDetails)) {
      updatedIncome.additionalDetails = reduce(
        updatedIncome.additionalDetails,
        (result, value, key) => {
          return result.concat(`${key}: ${value};`)
        },
        ''
      )
    }
    return updatedIncome
  })

  return updatedIncomes.map((income) =>
    omit(income, ['key', 'type', 'files', 'householdMemberName'])
  )
}

export default updateIncomeParams
