import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const propTypes = {
  heading: PropTypes.string.isRequired,
  uuid: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
}

const defaultProps = {}

function FormAccordionItem({ heading, uuid, children }) {
  const [showValidationError, setShowValidationError] = useState(false)

  return (
    <AccordionItem className="form-section" uuid={uuid}>
      <div className="row">
        <AccordionItemHeading aria-level={2} className="col-12">
          <AccordionItemButton>{heading}</AccordionItemButton>
        </AccordionItemHeading>
      </div>
      <AccordionItemPanel>
        {children(setShowValidationError)}
      </AccordionItemPanel>
      {showValidationError && (
        <div className="row form-wide-error">
          <div className="col-12">
            <span className="error-message">
              Please review and resolve the errors on this form and resubmit.
            </span>
          </div>
        </div>
      )}
    </AccordionItem>
  )
}

FormAccordionItem.propTypes = exact(propTypes)
FormAccordionItem.defaultProps = defaultProps

export default React.memo(FormAccordionItem)
