import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames'
import { usHousingConsultantsLogo } from 'images'

const propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  contactEmail: PropTypes.string.isRequired,
  disableSidebar: PropTypes.bool.isRequired,
}
const defaultProps = {}

function Header({
  toggleSidebar,
  sidebarVisible,
  contactEmail,
  disableSidebar,
}) {
  return (
    <header>
      {!disableSidebar && (
        <button
          aria-label={`${
            sidebarVisible ? 'Hide' : 'Show'
          } application steps navigation`}
          type="button"
          onClick={toggleSidebar}
          className={classNames('menu-action', { opened: sidebarVisible })}
        />
      )}
      <img
        className={classNames('app-logo', {
          'sidebar-disabled': disableSidebar,
        })}
        src={usHousingConsultantsLogo}
        alt="US Housing Consultants Logo"
      />
      <a
        className="contact-link"
        href={`mailto:${contactEmail}`}
        target="_blank"
        rel="noreferrer"
      >
        Contact
      </a>
    </header>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default memo(Header)
