import React, { createRef, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Spinner, Button, ButtonArea } from 'lp-components'
import SignatureCanvas from 'react-signature-canvas'
import classNames from 'classnames'

const propTypes = {
  signatures: PropTypes.arrayOf(Types.file),
  saveSignature: PropTypes.func.isRequired,
  removeSignature: PropTypes.func.isRequired,
  adultMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
  memberIdUploading: PropTypes.string,
  signatureIdRemoving: PropTypes.string,
}

const defaultProps = {
  signatures: null,
  memberIdUploading: null,
  signatureIdRemoving: null,
}

function SignaturesForm({
  signatures,
  saveSignature,
  removeSignature,
  adultMembers,
  memberIdUploading,
  signatureIdRemoving,
}) {
  const sigRefsRef = useRef(adultMembers.map(createRef))
  const sigRefs = sigRefsRef.current

  const handleSave = (sigRef, memberId) => {
    if (!sigRef.current) return

    const signatureData = sigRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png')
    const file = {
      url: signatureData,
      name: 'signature.png',
    }
    saveSignature(file, memberId)
  }

  const handleClear = (sigRef, savedSignature) => {
    if (sigRef.current) sigRef.current.clear()
    if (savedSignature) removeSignature(savedSignature.contentDocumentId)
  }

  return (
    <div className="form-section">
      <div className="heading-wrapper">
        <h2>Sign</h2>
      </div>
      <div className="row">
        <div className="col-12">
          <p>
            Under penalty of perjury, I/we certify that the information
            presented in this certification is true and accurate to the best of
            my/our knowledge. The undersigned further understand(s) that
            providing false representations herein constitutes an act of fraud.
            False, misleading or incomplete information may result in the
            termination of a lease agreement.
          </p>
        </div>
      </div>
      {signatures ? (
        adultMembers.map((member, index) => {
          const inputId = `sign-${member.id}`
          const sigRef = sigRefs[index]
          const savedSignature = signatures.find(
            ({ firstPublishLocationId }) =>
              firstPublishLocationId === member.sfid
          )
          const hasSavedSignature = !!savedSignature
          const memberFullName = `${member.firstName} ${member.lastName}`
          const isUploading = memberIdUploading === member.sfid
          const isRemoving =
            savedSignature &&
            signatureIdRemoving === savedSignature.contentDocumentId

          return (
            <div key={member.id} className="signature-field">
              <div className="row">
                <div className="col-12">
                  <label htmlFor={inputId}>{memberFullName}'s Signature</label>
                  {hasSavedSignature ? (
                    <p className="sig-saved-text">
                      Signature for {memberFullName} is saved
                    </p>
                  ) : (
                    <SignatureCanvas
                      penColor="black"
                      ref={sigRef}
                      canvasProps={{
                        className: 'signature-canvas',
                        id: inputId,
                      }}
                    />
                  )}
                </div>
              </div>
              <ButtonArea>
                <button
                  type="button"
                  onClick={() => handleClear(sigRef, savedSignature)}
                  className={classNames('link-warn', {
                    'remove-icon': !isRemoving,
                  })}
                  disabled={isRemoving}
                >
                  {isRemoving ? <Spinner /> : 'Clear'}
                </button>
                {!hasSavedSignature && (
                  <Button
                    className={classNames({ 'in-progress': isUploading })}
                    onClick={() => handleSave(sigRef, member.sfid)}
                    disabled={isUploading}
                  >
                    Save Signature
                  </Button>
                )}
              </ButtonArea>
            </div>
          )
        })
      ) : (
        <Spinner />
      )}
    </div>
  )
}

SignaturesForm.propTypes = exact(propTypes)
SignaturesForm.defaultProps = defaultProps

export default React.memo(SignaturesForm)
