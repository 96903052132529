import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { ButtonArea } from 'lp-components'
import {
  withJsonApiProps,
  withLayout,
  getInterviewerStatus,
  goToNextStep,
  useOrderedSteps,
  useFlashMessages,
} from 'utils'
import { updateTenantCaseInterviewer, createTenantCaseComment } from 'api'
import { ApplicationLayout } from 'components'
import { STEP } from 'config'
import { RequestCallBackForm } from 'forms'

const propTypes = {
  interviewer: Types.interviewer,
  isDuringWorkingHours: PropTypes.bool.isRequired,
  caseNumber: PropTypes.string.isRequired,
}

const defaultProps = {
  interviewer: null,
}

function Interview({ interviewer, isDuringWorkingHours, caseNumber }) {
  const { showFlashFailure, showFlashSuccess } = useFlashMessages()
  const [steps] = useOrderedSteps()
  const { statusClassName, statusLabel, statusDescription, isAvailable } =
    getInterviewerStatus(interviewer, isDuringWorkingHours)

  async function handleRequestCallBackSubmit(values, resetForm) {
    try {
      await createTenantCaseComment({ caseNumber, ...values })
      showFlashSuccess('Your request has been submitted successfully.')
      resetForm()
    } catch (error) {
      showFlashFailure(
        'There was an error submitting your request. Please try again later.'
      )
    }
  }
  return (
    <div className="interview-page">
      <Helmet>
        <title>Interview</title>
      </Helmet>
      <div className="page-header">
        <h1>Interview</h1>
        <p>
          Welcome to the live interview part of the application process. During
          this interview, we will clarify the information you provided, and you
          will be able to ask questions about the application process. Please
          keep in mind that the housing specialist will not have any specific
          information about the property such as amenities, move-in date
          availability, or maintenance requests.
        </p>
      </div>
      <div className="info-container">
        <div>
          <h2>Interview Details</h2>
          <p>
            During this interview, you will work directly with a Housing
            Specialist from US Housing Consultants. They will confirm your
            information and let you know if any other documentation is required.
          </p>
          <div className="error-container">
            <p>
              Your application has not yet been submitted. It will only be
              submitted after the interview with our housing specialist has been
              completed.
            </p>
          </div>
        </div>
      </div>
      <div className="info-container">
        <div>
          <div className="interview-status-header">
            <h2>Zoom Interviewer Status</h2>
            <div className="status-label">
              <span className={statusClassName} />
              <span>{statusLabel}</span>
            </div>
          </div>
          <p>{statusDescription}</p>
          {isAvailable && (
            <ButtonArea>
              <a
                className="interview-button button-primary"
                href={interviewer.zoomUrl}
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  const updatedCase = await updateTenantCaseInterviewer(
                    interviewer.sfid
                  )
                  if (updatedCase && steps[1].name === STEP.INTERVIEW.name)
                    goToNextStep(STEP.CONTACT_INFORMATION)
                }}
              >
                Join Waiting Room
              </a>
            </ButtonArea>
          )}
        </div>
      </div>
      <RequestCallBackForm
        initialValues={{
          phoneNumber: '',
          comments: '',
        }}
        onSubmit={handleRequestCallBackSubmit}
      />
    </div>
  )
}

Interview.propTypes = propTypes
Interview.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(Interview)
