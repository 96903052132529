import { INTERVIEWER_STATUS } from 'config'

function getInterviewerStatus(interviewer, isDuringWorkingHours) {
  if (interviewer) return INTERVIEWER_STATUS.AVAILABLE

  if (isDuringWorkingHours) return INTERVIEWER_STATUS.AVAILABLE_SOON

  return INTERVIEWER_STATUS.UNAVAILABLE
}

export default getInterviewerStatus
