import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { HOUSEHOLD_ASSETS_QUESTIONS } from 'config'
import { convertBoolToString, formatDollars } from 'utils'

const propTypes = {
  householdAssets: PropTypes.arrayOf(Types.householdAsset).isRequired,
}

const defaultProps = {}

function ReviewHouseholdAssets({ householdAssets }) {
  return (
    <dl>
      {HOUSEHOLD_ASSETS_QUESTIONS.map(({ name, label }) => {
        const householdAsset = householdAssets.find(
          ({ shortenedType }) => shortenedType === name
        )
        if (!householdAsset) return null

        const { id, applies, amount, source, income } = householdAsset
        return (
          <React.Fragment key={id}>
            <dt>{label}</dt>
            <dd>{convertBoolToString(applies)}</dd>
            {applies && (
              <div className="asset-details">
                <dt>Amount</dt>
                <dd>{formatDollars(amount)}</dd>
                <dt>Bank</dt>
                <dd>{source}</dd>
                <dt>Income Accrued</dt>
                <dd>{formatDollars(income)}</dd>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </dl>
  )
}

ReviewHouseholdAssets.propTypes = exact(propTypes)
ReviewHouseholdAssets.defaultProps = defaultProps

export default React.memo(ReviewHouseholdAssets)
