import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Input, Select, RadioGroup, OptionalLabel } from 'components'
import {
  US_STATES,
  ENROLLMENT_OPTIONS,
  BOOLEAN_STRING_OPTIONS,
  STUDENT_ADDITIONAL_QUESTIONS,
} from 'config'

const propTypes = {
  studentId: PropTypes.number.isRequired,
  formIsValid: PropTypes.bool.isRequired,
  setShowValidationError: PropTypes.func.isRequired,
  isAdultStudent: PropTypes.bool.isRequired,
}

const defaultProps = {}

function StudentStatusFormFields({
  studentId,
  isAdultStudent,
  formIsValid,
  setShowValidationError,
}) {
  useEffect(() => {
    setShowValidationError(!formIsValid)
  }, [formIsValid, setShowValidationError])

  const optionalLabelComponent = isAdultStudent ? undefined : OptionalLabel

  return (
    <>
      <div className="row">
        <div className="col-12">
          <RadioGroup
            name={`${studentId}.studentEnrollment`}
            label="Full or Part-Time Student?"
            options={ENROLLMENT_OPTIONS}
            required
          />
        </div>
      </div>
      <div className="row sub-section">
        <div className="col-12">
          <h3>Education Institution Information</h3>
          <p>Only required for students 18 or older</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            name={`${studentId}.studentInstitutionName`}
            label="Name of Institution"
            labelComponent={optionalLabelComponent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            name={`${studentId}.studentInstitutionAddress1`}
            label="Address Line 1"
            labelComponent={optionalLabelComponent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            name={`${studentId}.studentInstitutionAddress2`}
            label="Address Line 2"
            labelComponent={OptionalLabel}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Input
            name={`${studentId}.studentInstitutionCity`}
            label="City"
            labelComponent={optionalLabelComponent}
          />
        </div>
        <div className="col-6">
          <Select
            name={`${studentId}.studentInstitutionState`}
            label="State"
            labelComponent={optionalLabelComponent}
            placeholder="Select"
            options={US_STATES}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Input
            name={`${studentId}.studentInstitutionZip`}
            label="ZIP Code"
            labelComponent={optionalLabelComponent}
          />
        </div>
      </div>
      {isAdultStudent && (
        <>
          <div className="row sub-section">
            <div className="col-12">
              <h3>Additional Questions</h3>
            </div>
          </div>
          {STUDENT_ADDITIONAL_QUESTIONS.map(({ label, name }) => (
            <div className="row" key={name}>
              <div className="col-12">
                <RadioGroup
                  name={`${studentId}.${name}`}
                  label={label}
                  options={BOOLEAN_STRING_OPTIONS}
                  required
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
}

StudentStatusFormFields.propTypes = exact(propTypes)
StudentStatusFormFields.defaultProps = defaultProps

export default React.memo(StudentStatusFormFields)
