import { PERSONAL_VERIFICATION_FILE_CATEGORIES } from 'config'

const { socialSecurityCards, birthCertificates, additionalDocuments } =
  PERSONAL_VERIFICATION_FILE_CATEGORIES

function groupFilesByPersonalVerificationCategory(files) {
  const categorizedFiles = {
    [socialSecurityCards]: [],
    [birthCertificates]: [],
    [additionalDocuments]: [],
  }

  files.forEach((file) => {
    const titleParts = file.name.split('_')
    const category = titleParts[0]

    if (!categorizedFiles[category]) return

    categorizedFiles[category].push(file)
  })

  return categorizedFiles
}

export default groupFilesByPersonalVerificationCategory
