import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { STEP } from 'config'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import {
  ReviewHouseholdMembers,
  ReviewContactInformation,
  ReviewApplicationInformation,
  ReviewStudentStatus,
  ReviewHouseholdIncomes,
  ReviewHouseholdAssets,
  ReviewPersonalVerification,
} from 'components'

const propTypes = {
  tenantApplication: Types.tenantApplication.isRequired,
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
  tenant: Types.tenant.isRequired,
  householdIncomes: PropTypes.arrayOf(Types.householdIncome).isRequired,
  householdAssets: PropTypes.arrayOf(Types.householdAsset).isRequired,
  tenantCaseFiles: PropTypes.arrayOf(Types.file).isRequired,
  certType: PropTypes.string,
}

const defaultProps = {}

const {
  HOUSEHOLD_MEMBERS,
  CONTACT_INFORMATION,
  APPLICATION_INFORMATION,
  STUDENT_STATUS,
  HOUSEHOLD_INCOME,
  HOUSEHOLD_ASSETS,
  PERSONAL_VERIFICATION,
} = STEP

function ReviewForms({
  householdMembers,
  tenant,
  tenantApplication,
  householdIncomes,
  householdAssets,
  tenantCaseFiles,
  certType,
}) {
  const students = householdMembers.filter(
    (householdMember) => householdMember.student
  )

  const reviewSteps = [
    {
      ...HOUSEHOLD_MEMBERS,
      component: ReviewHouseholdMembers,
      props: { householdMembers },
    },
    {
      ...CONTACT_INFORMATION,
      component: ReviewContactInformation,
      props: { tenant },
    },
    {
      ...APPLICATION_INFORMATION,
      component: ReviewApplicationInformation,
      props: { tenantApplication, certType },
    },
    {
      ...STUDENT_STATUS,
      component: ReviewStudentStatus,
      props: { students },
    },
    {
      ...HOUSEHOLD_INCOME,
      component: ReviewHouseholdIncomes,
      props: { householdIncomes },
    },
    {
      ...HOUSEHOLD_ASSETS,
      component: ReviewHouseholdAssets,
      props: { householdAssets },
    },
    {
      ...PERSONAL_VERIFICATION,
      component: ReviewPersonalVerification,
      props: { tenantCaseFiles },
    },
  ]

  return (
    <div className="form-section">
      <div className="row">
        <div className="col-12">
          <h2>Review</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Accordion allowZeroExpanded allowMultipleExpanded>
            {reviewSteps.map(
              ({ name, path, component: ReviewComponent, props }) => {
                if (!ReviewComponent) return null

                return (
                  <AccordionItem
                    uuid={path}
                    key={path}
                    className="form-section review-section"
                  >
                    <div className="row">
                      <AccordionItemHeading aria-level={3} className="col-12">
                        <AccordionItemButton>{name}</AccordionItemButton>
                      </AccordionItemHeading>
                    </div>
                    <AccordionItemPanel>
                      <div className="row">
                        <div className="col-12">
                          <ReviewComponent {...props} />
                          <a className="edit-link" href={path}>
                            Edit
                          </a>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                )
              }
            )}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

ReviewForms.propTypes = exact(propTypes)
ReviewForms.defaultProps = defaultProps

export default React.memo(ReviewForms)
