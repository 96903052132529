import { HOUSEHOLD_INCOME_SECTIONS, INITIAL_FORM_VALUES } from 'config'
import { getIncomeQuestions } from 'utils'
import uuid from 'react-uuid'

function createInitialIncomes({
  fundingProgram,
  householdMember,
  existingIncomes = {},
}) {
  const initialIncomes = existingIncomes

  HOUSEHOLD_INCOME_SECTIONS.forEach((section) => {
    const questions = getIncomeQuestions({
      fundingProgram,
      householdMember,
      sectionQuestions: section.questions,
    })

    // if this is part of the questions for the householdMember,
    // then include it in the incomes to create, skip if already exists
    questions.forEach(({ questionType }) => {
      if (initialIncomes[questionType]) return

      initialIncomes[questionType] = [
        {
          ...INITIAL_FORM_VALUES.HOUSEHOLD_INCOMES,
          key: uuid(),
          householdMemberSfid: householdMember.sfid,
          shortenedType: questionType,
        },
      ]
    })
  })

  return initialIncomes
}

export default createInitialIncomes
