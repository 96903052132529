import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { ZeroIncomeAccordionForm } from 'forms'
import {
  groupBy,
  every,
  flatten,
  values,
  omit,
  isEmpty,
  isBoolean,
} from 'lodash'
import { createInitialZeroIncomes } from 'utils'
import { checkmark } from 'images'
import classNames from 'classnames'

const propTypes = {
  stepNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: Types.zeroIncomeSection.isRequired,
  uuid: PropTypes.string.isRequired,
  householdMember: Types.householdMember.isRequired,
  handleSubmitCrud: PropTypes.func.isRequired,
  setSectionCompletionStatus: PropTypes.func.isRequired,
}

const defaultProps = {}

function ZeroIncomeSectionAccordionItem({
  stepNumber,
  title,
  data,
  uuid,
  householdMember,
  handleSubmitCrud,
  setSectionCompletionStatus,
}) {
  const groupedIncomes = groupBy(householdMember.zeroIncomes, 'shortenedType')

  const initialZeroIncomes = createInitialZeroIncomes({
    householdMember,
    existingIncomes: groupedIncomes,
  })

  const questionTypesFormSection = data['questions'].map((q) => q.questionType)

  const filteredZeroIncomes = Object.keys(initialZeroIncomes)
    .filter((key) => questionTypesFormSection.includes(key))
    .reduce((acc, key) => {
      acc[key] = initialZeroIncomes[key]
      return acc
    }, {})

  const applicableQuestions = data['questions']

  const isSectionInitiallyComplete = every(
    filteredZeroIncomes,
    (zero_incomes) => {
      return every(zero_incomes, ({ applies, additionalNotes }) => {
        return (
          isBoolean(applies) &&
          ((applies && !isEmpty(additionalNotes)) || !applies)
        )
      })
    }
  )

  const [isSectionComplete, setIsSectionComplete] = useState(
    isSectionInitiallyComplete
  )

  const sectionName = `${householdMember.id}_${title}`

  useEffect(() => {
    setSectionCompletionStatus(sectionName, isSectionComplete)
  }, [setSectionCompletionStatus, sectionName, isSectionComplete])

  return (
    <AccordionItem className="section-accordion-item" uuid={uuid}>
      <div className="row sub-section">
        <AccordionItemHeading
          aria-level={3}
          className="col-12 no-bottom-margin"
        >
          <AccordionItemButton>
            <div
              className={classNames('step-number-box', {
                complete: isSectionComplete,
              })}
            >
              {isSectionComplete ? (
                <img
                  className="checkmark"
                  src={checkmark}
                  alt={`${title} is complete for ${householdMember.firstName} ${householdMember.lastName}`}
                />
              ) : (
                stepNumber
              )}
            </div>
            {title}
          </AccordionItemButton>
        </AccordionItemHeading>
      </div>
      <AccordionItemPanel>
        <ZeroIncomeAccordionForm
          initialValues={{
            zero_incomes: filteredZeroIncomes,
          }}
          setSectionIncomplete={() => setIsSectionComplete(false)}
          onSubmit={async ({ zero_incomes }) => {
            const isSubmitSuccess = await handleSubmitCrud(
              zero_incomes,
              filteredZeroIncomes,
              (zeroIncomes) => {
                const flattenedIncomes = flatten(values(zeroIncomes))
                return flattenedIncomes.map((income) => omit(income, ['key']))
              }
            )

            if (isSubmitSuccess) {
              setIsSectionComplete(true)
            }

            return isSubmitSuccess
          }}
          householdMemberSfid={householdMember.sfid}
          sourceHeader={data['sourceHeader']}
          questions={applicableQuestions}
        />
      </AccordionItemPanel>
    </AccordionItem>
  )
}

ZeroIncomeSectionAccordionItem.propTypes = exact(propTypes)
ZeroIncomeSectionAccordionItem.defaultProps = defaultProps

export default React.memo(ZeroIncomeSectionAccordionItem)
