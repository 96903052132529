import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { INITIAL_FORM_VALUES, STEP } from 'config'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  goToNextStep,
  useStepFormSubmit,
  useOrderedSteps,
} from 'utils'
import { ApplicationLayout } from 'components'
import { HouseholdMembersForm } from 'forms'
import { isEmpty } from 'lodash'
import {
  createHouseholdMembers,
  updateHouseholdMembers,
  deleteHouseholdMember,
} from 'api'
import uuid from 'react-uuid'

const propTypes = {
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function HouseholdMembers({ householdMembers }) {
  const [steps] = useOrderedSteps()
  const { handleSubmitCrud } = useStepFormSubmit({
    createRequest: createHouseholdMembers,
    updateRequest: updateHouseholdMembers,
    deleteRequest: deleteHouseholdMember,
  })
  const initialHouseholdMembers = isEmpty(householdMembers)
    ? [{ ...INITIAL_FORM_VALUES.HOUSEHOLD_MEMBERS, key: uuid() }]
    : householdMembers
  return (
    <>
      <Helmet>
        <title>Household Members</title>
      </Helmet>
      <div className="page-header">
        <h1>Household Members</h1>
        <p>
          Fill out the information for each of the people living within your
          home, including yourself. To add another person after completing a
          section, click "Add Another Person" in the lower left of the section
          you've just completed.
        </p>
      </div>
      <HouseholdMembersForm
        initialValues={{
          householdMembers: initialHouseholdMembers,
        }}
        onSubmit={async ({ householdMembers }) => {
          const isSubmitSuccess = await handleSubmitCrud(
            householdMembers,
            initialHouseholdMembers
          )
          if (isSubmitSuccess) {
            const nextStep =
              steps[1].name === STEP.INTERVIEW.name
                ? STEP.INTERVIEW
                : STEP.CONTACT_INFORMATION
            goToNextStep(nextStep)
          }
        }}
      />
    </>
  )
}

HouseholdMembers.propTypes = propTypes
HouseholdMembers.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(HouseholdMembers)
