import React from 'react'
import exact from 'prop-types-exact'
import { ConsentDocument } from 'components'

const propTypes = {}

const defaultProps = {}

function ReviewConsent() {
  return (
    <div className="form-section">
      <div className="row">
        <div className="col-12">
          <h2>Consent</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 consent-document">
          <ConsentDocument />
        </div>
      </div>
    </div>
  )
}

ReviewConsent.propTypes = exact(propTypes)
ReviewConsent.defaultProps = defaultProps

export default React.memo(ReviewConsent)
