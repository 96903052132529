import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, PhoneInput } from 'components'
import { SubmitButton, ButtonArea } from 'lp-components'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { logout } from 'api'
import { push, useFlashMessages } from 'utils'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const loginSchema = Yup.object().shape(
  {
    caseNumber: Yup.string().required('Case Number is required'),
    email: Yup.string()
      .email()
      .when('phoneNumber', {
        is: isEmpty,
        then: (schema) => schema.required('Phone number or email is required'),
      }),
    phoneNumber: Yup.string().when('email', {
      is: isEmpty,
      then: (schema) => schema.required('Phone number or email is required'),
    }),
  },
  [['email', 'phoneNumber']]
)

function LoginForm({ onSubmit, initialValues }) {
  const { showFlashFailure } = useFlashMessages()
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false)
  const [isEmailEntered, setIsEmailEntered] = useState(false)

  const handlePhoneChange = (e) => {
    setIsPhoneNumberEntered(!isEmpty(e.target.value))
  }

  const handleEmailChange = (e) => {
    setIsEmailEntered(!isEmpty(e.target.value))
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={loginSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Input
            className="no-bottom-margin"
            name="caseNumber"
            label="Case Number"
            required
            disabled
          />
          <button
            type="button"
            className="link-primary change-case-number"
            onClick={async () => {
              try {
                await logout()
                push('/login')
              } catch (error) {
                showFlashFailure(error.response.data.message)
              }
            }}
          >
            Not your Case Number?
          </button>
          <PhoneInput
            name="phoneNumber"
            onChange={handlePhoneChange}
            disabled={isEmailEntered}
            required
          />
          <div className="or-break-line">
            <span>OR</span>
          </div>
          <Input
            name="email"
            label="Email"
            onChange={handleEmailChange}
            disabled={isPhoneNumberEntered}
            required
          />
          <ButtonArea>
            <SubmitButton
              submitting={isSubmitting}
              className="button-full-width"
            >
              Log Into Your Application
            </SubmitButton>
          </ButtonArea>
        </Form>
      )}
    </Formik>
  )
}

LoginForm.propTypes = propTypes
LoginForm.defaultProps = defaultProps

export default React.memo(LoginForm)
