import { flatMap, groupBy, isEmpty } from 'lodash'
import { uploadFiles } from 'api'

async function submitFiles(incomesArray) {
  for (const income of incomesArray) {
    if (isEmpty(income.files)) continue

    const filteredFiles = income.files.filter((file) => !file.contentDocumentId)
    if (!isEmpty(filteredFiles)) {
      await uploadFiles({ files: filteredFiles, objectId: income.sfid })
    }
  }
}

async function processIncomeFiles(updatedIncomes, formIncomes) {
  const groupedUpdatedIncomes = groupBy(updatedIncomes, 'shortenedType')

  /* There are two objects, groupedUpdatedIncomes and formIncomes,
  each containing arrays of objects. The updatedIncomes will be all of the incomes
  on the member, whereas the formIncomes will only include those in the form
  section that was submitted. The two objects will have overlapping keys,
  and each key in both objects corresponds to an array of objects.
  Loop through the values in groupedUpdatedIncome, and for each value,
  loop through the array of objects in that value. For each object,
  find the corresponding object in formIncomes (using the same key and the same index),
  and return an object where the files property in the object from groupedUpdatedIncome
  is replaced with the files property from the corresponding object in formIncomes */

  const flattenedIncomes = flatMap(
    groupedUpdatedIncomes,
    (incomesGroup, key) => {
      return incomesGroup.reduce((result, incomeItem, index) => {
        const matchingFormIncome = formIncomes[key]
        if (matchingFormIncome) {
          result.push({ ...incomeItem, files: matchingFormIncome[index].files })
        }
        return result
      }, [])
    }
  )

  return await submitFiles(flattenedIncomes)
}

export default processIncomeFiles
