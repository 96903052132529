import { useState } from 'react'
import {
  filter,
  first,
  isEmpty,
  last,
  partition,
  reject,
  some,
  values,
} from 'lodash'
import { SAVE_ERROR_MESSAGE } from 'config'
import useFlashMessages from './useFlashMessages'
import { convertFromJsonApi, convertToCamelCase } from 'utils'

function useStepFormSubmit({
  createRequest,
  updateRequest,
  deleteRequest,
  filesProcessor,
  successFlashMessage,
}) {
  const { showFlashSuccess, showFlashFailure } = useFlashMessages()
  const [disableSingleFormFlash, setDisableSingleFormFlash] = useState(false)
  const errors = []
  const responses = []

  async function handleSubmitUpdate(formParams) {
    const formValues = first(values(formParams))

    await handleRequest(formValues, updateRequest)

    return handleResult()
  }

  async function handleSubmitCrud(
    resources,
    initialResources,
    formatResourcesForRequest = null
  ) {
    const formattedResources = formatResourcesForRequest
      ? formatResourcesForRequest(resources)
      : resources
    const formattedInitialResources = formatResourcesForRequest
      ? formatResourcesForRequest(initialResources)
      : initialResources

    const realInitialResources = filter(formattedInitialResources, 'id')
    const [updatedResources, newResources] = partition(formattedResources, 'id')
    const removedResources = reject(realInitialResources, (initialResource) => {
      return some(updatedResources, (updatedResource) => {
        return initialResource.id === updatedResource.id
      })
    })

    await handleRequest(newResources, createRequest)
    await handleRequest(updatedResources, updateRequest)
    await handleMultipleRequests(removedResources, deleteRequest)
    // Use unformatted so files are still included
    if (filesProcessor) await handleFileSubmit(resources)

    return handleResult()
  }

  async function handleRequest(resources, request) {
    if (isEmpty(resources)) return

    try {
      const response = await request(resources)
      responses.push(response)
    } catch (err) {
      errors.push(err.message)
    }
  }

  async function handleMultipleRequests(resources, request) {
    if (isEmpty(resources)) return

    for (const resource of resources) {
      try {
        const response = await request(resource.id)
        responses.push(response)
      } catch (err) {
        errors.push(err.message)
      }
    }
  }

  async function handleFileSubmit(resources) {
    if (isEmpty(responses) || isEmpty(resources)) return

    // Use last response as it is returned after final operation with latest changes
    const latestResourcesRaw = last(responses).data
    const latestResources = convertToCamelCase(
      convertFromJsonApi(latestResourcesRaw)
    )

    try {
      await filesProcessor(latestResources, resources)
    } catch (err) {
      errors.push(err.message)
    }
  }

  function handleResult() {
    if (isEmpty(errors)) {
      if (!disableSingleFormFlash && successFlashMessage) {
        showFlashSuccess(successFlashMessage)
      }
      return true
    }

    if (!disableSingleFormFlash) {
      showFlashFailure(SAVE_ERROR_MESSAGE)
    }
    return false
  }

  return {
    setDisableSingleFormFlash,
    handleSubmitUpdate,
    handleSubmitCrud,
  }
}

export default useStepFormSubmit
