import React from 'react'
import PropTypes from 'prop-types'
import { MaskedInput } from 'components'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

const defaultProps = {
  label: 'Telephone',
}

function PhoneInput({ name, label, ...rest }) {
  return (
    <MaskedInput
      name={name}
      label={label}
      maskOptions={{
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-',
      }}
      {...rest}
    />
  )
}

PhoneInput.propTypes = propTypes
PhoneInput.defaultProps = defaultProps

export default React.memo(PhoneInput)
