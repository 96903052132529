import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import { SubmitButton } from 'lp-components'
import RequestCallBackFormFields from './RequestCallBackFormFields'
import { Form, Formik } from 'formik'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

function RequestCallBackForm({ onSubmit, initialValues }) {
  const validationSchema = Yup.object({
    phoneNumber: Yup.string().required('Phone Number is required'),
    comments: Yup.string().required('Comment is required'),
  })

  return (
    <Accordion allowZeroExpanded allowMultipleExpanded>
      <AccordionItem className="form-section">
        <div className="row">
          <AccordionItemHeading aria-level={2} className="col-12">
            <AccordionItemButton>Request a callback</AccordionItemButton>
          </AccordionItemHeading>
        </div>
        <AccordionItemPanel>
          <div id="request-callback-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                onSubmit(values, resetForm)
              }}
            >
              {({ isSubmitting }) => (
                <Form noValidate>
                  <RequestCallBackFormFields />
                  <div className="row">
                    <div className="col-12">
                      <SubmitButton submitting={isSubmitting}>
                        Request Call Back
                      </SubmitButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

RequestCallBackForm.propTypes = exact(propTypes)
RequestCallBackForm.defaultProps = defaultProps

export default React.memo(RequestCallBackForm)
