import { useCallback, useContext } from 'react'
import { FlashMessageContext } from 'contexts'

/**
 * This hook returns two functions to display flash success and failure messages
 *
 * @name useFlashMessages
 */

function useFlashMessages() {
  const setFlash = useContext(FlashMessageContext)

  /**
   * @name showFlashSuccess
   * @param {String} message - Message to display
   */
  const showFlashSuccess = useCallback(
    (message) => {
      setFlash({ message, isError: false })
    },
    [setFlash]
  )

  /**
   * @name showFlashFailure
   * @param {String} message - Message to display
   */
  const showFlashFailure = useCallback(
    (message) => {
      setFlash({ message, isError: true })
    },
    [setFlash]
  )

  return { showFlashSuccess, showFlashFailure }
}

export default useFlashMessages
