import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { gatherYourDocumentsImage } from 'images'

const propTypes = {
  overviewVideoUrl: PropTypes.string.isRequired,
}

const defaultProps = {}

function ApplicationInstructions({ overviewVideoUrl }) {
  return (
    <>
      <div className="application-overview-card info-container">
        <div className="application-overview-text">
          <h2 className="application-overview-title primary-color">
            1. Learn About the Process
          </h2>
          <p className="application-overview-paragraph">
            Throughout this process, you will be led through a multi-step
            application. Some pages may require you to upload documentation. The
            documents needed are detailed below. If you need to exit and come
            back to this application, your progress will be saved by clicking on
            “Save & Continue”. You'll have an opportunity to do this once you
            complete any page.
          </p>
        </div>
        <iframe
          className="application-overview-iframe"
          src={overviewVideoUrl}
          title="Video Overview"
          allowFullScreen
        />
      </div>
      <div className="application-overview-card info-container">
        <div className="application-overview-text">
          <h2 className="application-overview-title primary-color">
            2. Gather Your Documents
          </h2>
          <p className="application-overview-paragraph">
            These are documents that you will be required to provide throughout
            the application process:
          </p>

          <ul className="document-list">
            <li>Six Recent Paystubs (if employed)</li>
            <li>
              Award Letters for any public assistance, SSDI, or Social Security
            </li>
            <li>Child Support Documentation</li>
            <li>Proof of any other sources of income</li>
            <li>Tax Returns (If available)</li>
          </ul>
        </div>
        <img alt="" src={gatherYourDocumentsImage} />
      </div>
    </>
  )
}

ApplicationInstructions.propTypes = exact(propTypes)
ApplicationInstructions.defaultProps = defaultProps

export default React.memo(ApplicationInstructions)
