import { first, values } from 'lodash'
import { PERSONAL_VERIFICATION_FILE_CATEGORIES } from 'config'

function stripPersonalVerificationPrefixFromFileName(fileName) {
  const nameParts = fileName.split('_')
  const hasPersonalVerificationCategory = values(
    PERSONAL_VERIFICATION_FILE_CATEGORIES
  ).includes(first(nameParts))

  return hasPersonalVerificationCategory
    ? nameParts.slice(1).join('_')
    : fileName
}

export default stripPersonalVerificationPrefixFromFileName
