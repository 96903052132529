import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { MaskedInput } from 'components'

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
}

const defaultProps = {}

function SocialSecurityInput({ name, value, updateValue }) {
  const rawValue = value.split('-').join('')
  const [actualSsn, setActualSsn] = useState(rawValue)
  const [maskedSsn, setMaskedSsn] = useState(starredMask(rawValue))

  function handleChange(event) {
    const prevValue = maskedSsn
    const newValue = event.target.value.split('-').join('')
    let actual = actualSsn

    // typing forward
    if (newValue.length > prevValue.length) {
      const newChar = newValue.split('').pop()
      actual = `${actual}${newChar}`
    }
    // backspacing / deleting
    else {
      const charsRemovedCount = prevValue.length - newValue.length
      actual = actual.substr(0, actual.length - charsRemovedCount)
    }

    setActualSsn(actual)
    setMaskedSsn(starredMask(actual))
  }

  function starredMask(ssn) {
    // replace numeric digits with * asterisk
    const valueHidden = ssn.replace(/[\d]/g, '*')

    if (valueHidden.length < 5) return valueHidden

    return valueHidden.slice(0, 5) + ssn.substr(5)
  }

  useEffect(() => {
    updateValue(actualSsn)
  }, [actualSsn, updateValue])

  return (
    <MaskedInput
      name={name}
      value={maskedSsn}
      onChange={handleChange}
      label="Social Security Number"
      placeholder="***-**-****"
      maskOptions={{
        blocks: [3, 2, 4],
        delimiter: '-',
      }}
      required
    />
  )
}

SocialSecurityInput.propTypes = exact(propTypes)
SocialSecurityInput.defaultProps = defaultProps

export default React.memo(SocialSecurityInput)
