import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import Textarea from './Textarea'
import Input from './Input'
import Select from './Select'
import RadioGroup from './RadioGroup'
import AdditionalInformationField from './AdditionalInformationField'
import { OptionalLabel } from 'components'
import classNames from 'classnames'

const propTypes = {
  subQuestions: PropTypes.arrayOf(Types.householdIncomeSubQuestion).isRequired,
  name: PropTypes.string.isRequired,
  indentSection: PropTypes.bool,
  value: PropTypes.objectOf(PropTypes.string).isRequired,
}

const defaultProps = {
  indentSection: false,
}

const SubQuestionComponents = {
  string: Input,
  textarea: Textarea,
  select: Select,
  radio: RadioGroup,
  additionalInformationField: AdditionalInformationField,
}

function HouseholdIncomeSubQuestions({
  value,
  subQuestions,
  name,
  indentSection,
}) {
  return (
    <div className="row">
      <div className={classNames({ 'row col-12 indent-row': indentSection })}>
        {subQuestions.map((subQuestion, index) => {
          const { subQuestionType, label, options, style, optional } =
            subQuestion
          const SubQuestionComponent =
            SubQuestionComponents[subQuestion.displayType]
          const isLastQuestion = index === subQuestions.length - 1
          return (
            <div
              key={subQuestionType}
              className={classNames(
                { 'keep-bottom-margin': !isLastQuestion },
                style
              )}
            >
              {subQuestion.displayType === 'additionalInformationField' ? (
                <SubQuestionComponent
                  name={`${name}[${subQuestionType}]`}
                  label={label}
                  additionalName={`${name}[${subQuestionType}Details]`}
                  answer={value[subQuestionType]}
                  additionalLabel="Provide Details"
                  additionalRequired
                />
              ) : (
                <SubQuestionComponent
                  name={`${name}[${subQuestionType}]`}
                  label={label}
                  labelComponent={optional && OptionalLabel}
                  options={options}
                  required={!optional}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

HouseholdIncomeSubQuestions.propTypes = exact(propTypes)
HouseholdIncomeSubQuestions.defaultProps = defaultProps

export default React.memo(HouseholdIncomeSubQuestions)
