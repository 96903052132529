import PropTypes from 'prop-types'

export const uploadDetails = PropTypes.shape({
  uploadHeader: PropTypes.string.isRequired,
  uploadSubHeader: PropTypes.string.isRequired,
})

export const householdIncomeSubQuestion = PropTypes.shape({
  subQuestionType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  style: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  optional: PropTypes.bool,
})

export const householdIncomeQuestion = PropTypes.shape({
  questionType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  uploads: uploadDetails.isRequired,
  sourceLabel: PropTypes.string,
  requirements: PropTypes.arrayOf(PropTypes.string),
  omitSource: PropTypes.bool,
  subQuestions: PropTypes.arrayOf(householdIncomeSubQuestion),
})

export const zeroIncomeQuestion = PropTypes.shape({
  questionType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  additionalNotes: PropTypes.arrayOf(PropTypes.string),
})

export const householdIncomeSection = PropTypes.shape({
  header: PropTypes.string.isRequired,
  sourceHeader: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(householdIncomeQuestion).isRequired,
})

export const zeroIncomeSection = PropTypes.shape({
  header: PropTypes.string.isRequired,
  sourceHeader: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(zeroIncomeQuestion).isRequired,
})

export const tenantApplication = PropTypes.shape({
  tenantCaseSfid: PropTypes.string,
  bedroomSizes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  crimeConvictions: PropTypes.string,
  crimeConvictionsComments: PropTypes.string,
  currentStep: PropTypes.string,
  displaced: PropTypes.string,
  displacedComments: PropTypes.string,
  domesticViolence: PropTypes.string,
  domesticViolenceComments: PropTypes.string,
  evicted: PropTypes.string,
  evictedComments: PropTypes.string,
  expectAdoptions: PropTypes.string,
  expectAdoptionsComments: PropTypes.string,
  expectHouseholdAdditions: PropTypes.string,
  expectHouseholdAdditionsComments: PropTypes.string,
  handicapAccessible: PropTypes.string,
  homeless: PropTypes.string,
  homelessComments: PropTypes.string,
  minorsAbsentHalfTime: PropTypes.string,
  minorsAbsentHalfTimeComments: PropTypes.string,
  otherRequestsComments: PropTypes.string,
  pets: PropTypes.string,
  petsComments: PropTypes.string,
  registeredSexOffender: PropTypes.string,
  registeredSexOffenderComments: PropTypes.string,
  serviceAnimal: PropTypes.string,
  serviceAnimalComments: PropTypes.string,
  veterans: PropTypes.string,
  veteransComments: PropTypes.string,
  visualHearingImpairment: PropTypes.string,
  visualHearingImpairmentComments: PropTypes.string,
})

export const asset = PropTypes.shape({
  fundingProgram: PropTypes.string,
  handicapUnitsCount: PropTypes.number,
  studioUnitsCount: PropTypes.number,
  oneBdUnitsCount: PropTypes.number,
  twoBdUnitsCount: PropTypes.number,
  threeBdUnitsCount: PropTypes.number,
  fourBdUnitsCount: PropTypes.number,
  fiveBdUnitsCount: PropTypes.number,
  sixBdUnitsCount: PropTypes.number,
})

export const tenantCase = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  householdName: PropTypes.string.isRequired,
})

export const tenant = PropTypes.shape({
  email: PropTypes.string,
  emergencyContactAddress1: PropTypes.string,
  emergencyContactAddress2: PropTypes.string,
  emergencyContactCity: PropTypes.string,
  emergencyContactFirstname: PropTypes.string,
  emergencyContactLastname: PropTypes.string,
  emergencyContactPhone: PropTypes.string,
  emergencyContactRelationship: PropTypes.string,
  emergencyContactState: PropTypes.string,
  emergencyContactZip: PropTypes.string,
  mailingAddress1: PropTypes.string,
  mailingAddress2: PropTypes.string,
  mailingCity: PropTypes.string,
  mailingState: PropTypes.string,
  mailingZip: PropTypes.string,
  phone: PropTypes.string,
  physicalAddress1: PropTypes.string,
  physicalAddress2: PropTypes.string,
  physicalCity: PropTypes.string,
  physicalState: PropTypes.string,
  physicalZip: PropTypes.string,
})

export const householdAsset = PropTypes.shape({
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  applies: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  source: PropTypes.string,
  type: PropTypes.string,
  income: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
})

export const householdIncome = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frequency: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  applies: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  source: PropTypes.string,
  householdMemberName: PropTypes.string,
})

export const householdIncomesFormValues = PropTypes.objectOf(
  PropTypes.arrayOf(householdIncome)
)

export const studentInformation = PropTypes.shape({
  studentEnrollment: PropTypes.string,
  studentInstitutionName: PropTypes.string,
  studentInstitutionAddressLine1: PropTypes.string,
  studentInstitutionAddressLine2: PropTypes.string,
  studentInstitutionAddressCity: PropTypes.string,
  studentInstitutionAddressState: PropTypes.string,
  studentInstitutionAddressZip: PropTypes.string,
  studentSection8Assistance: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentGraduateOrProfessionalStudent: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentVeteranStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  studentMinorMemberInHousehold: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentOtherDependents: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentOrphan: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  studentFosterCare: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  studentLivingWithParents: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentSeperateHouseholdFor1Year: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentParentsEligibleForSection8: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentFinancialAssistance: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentTanfIncome: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  studentMarriedAndJointReturn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentJobTrainingProgram: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentDependentFilingStatus: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  studentOnParentsTaxReturn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
})

export const householdMember = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  relationship: PropTypes.string,
  dateOfBirth: PropTypes.string,
  student: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ssn: PropTypes.string,
  income: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  race: PropTypes.string,
  ethnicity: PropTypes.string,
  gender: PropTypes.string,
  eligibleCitizen: PropTypes.string,
  eligibleCitizenDetails: PropTypes.string,
  maritalStatus: PropTypes.string,
  maritalStatusDetails: PropTypes.string,
  studentInformation,
  incomes: PropTypes.arrayOf(householdIncome),
})

export const step = PropTypes.shape({
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
})

export const interviewer = PropTypes.shape({
  name: PropTypes.string.isRequired,
  zoomUrl: PropTypes.string.isRequired,
  sfid: PropTypes.string.isRequired,
})

export const file = PropTypes.shape({
  contentDocumentId: PropTypes.string,
  firstPublishLocationId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
  url: PropTypes.string,
})
