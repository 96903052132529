import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import { Button } from 'lp-components'
import { withJsonApiProps, withLayout, push } from 'utils'
import { ApplicationLayout, ApplicationInstructions } from 'components'

const propTypes = {
  overviewVideoUrl: PropTypes.string.isRequired,
}

const defaultProps = {}

function ApplicationOverview({ overviewVideoUrl }) {
  return (
    <>
      <Helmet>
        <title>Application Overview</title>
      </Helmet>
      <div className="page-header">
        <h1>Application Overview</h1>
        <p>
          This includes both new applications and applications for
          recertifications and lease renewals
        </p>
      </div>
      <ApplicationInstructions overviewVideoUrl={overviewVideoUrl} />
      <div className="application-overview-card info-container">
        <h2 className="application-overview-title primary-color open-application">
          3. Fill Out Your Application
        </h2>
        <Button onClick={() => push('/')}>Open Your Application</Button>
      </div>
    </>
  )
}

ApplicationOverview.propTypes = propTypes
ApplicationOverview.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ApplicationOverview)
