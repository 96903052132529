import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { ButtonArea, Button } from 'lp-components'
import { isEmpty, some } from 'lodash'
import { push, goToNextStep, useFlashMessages } from 'utils'
import { SAVE_ERROR_MESSAGE } from 'config'

const propTypes = {
  formRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextStep: Types.step.isRequired,
  setDisableSingleFormFlash: PropTypes.func.isRequired,
  previousStep: Types.step,
  skipSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
}

const defaultProps = {
  previousStep: null,
  skipSubmit: false,
  isLoading: false,
}

function MultiFormButtons({
  formRefs,
  nextStep,
  setDisableSingleFormFlash,
  previousStep,
  skipSubmit,
  isLoading,
}) {
  const { showFlashFailure } = useFlashMessages()
  const isAnySubmitting = formRefs.some((obj) => obj?.current?.isSubmitting)

  const hasInvalidForm = () => {
    const formValidStatuses = formRefs.map((formRef) => formRef.current.isValid)
    return some(formValidStatuses, (valid) => !valid)
  }

  const submitAllForms = async (resolve, reject) => {
    const results = await Promise.all(
      formRefs.map((formRef) => formRef.current.submitForm())
    )
    if (some(results, (submitSuccess) => !submitSuccess)) return reject()

    resolve()
  }

  return (
    <ButtonArea>
      {previousStep && (
        <Button
          className="button-primary-outline"
          onClick={() => push(previousStep.path)}
        >
          Previous Step
        </Button>
      )}
      <Button
        // If the single accordion submit button is submitting or the form isLoading, do not allow for submitting this button.
        pristine={isLoading || isAnySubmitting}
        onClick={async () => {
          if (skipSubmit) return goToNextStep(nextStep)
          if (isEmpty(formRefs)) return

          try {
            setDisableSingleFormFlash(true)
            await new Promise(submitAllForms)
            goToNextStep(nextStep)
          } catch {
            setDisableSingleFormFlash(false)
            if (hasInvalidForm()) {
              return showFlashFailure(
                'Please review and resolve all form errors and resubmit.'
              )
            }
            showFlashFailure(SAVE_ERROR_MESSAGE)
          }
        }}
      >
        {!skipSubmit && 'Save & '}Continue
      </Button>
    </ButtonArea>
  )
}

MultiFormButtons.propTypes = exact(propTypes)
MultiFormButtons.defaultProps = defaultProps

export default React.memo(MultiFormButtons)
