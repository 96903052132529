import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button, ButtonArea } from 'lp-components'
import { push, goToNextStep } from 'utils'

const propTypes = {
  previousStep: PropTypes.object,
  nextStep: PropTypes.object,
}

const defaultProps = {
  previousStep: null,
  nextStep: null,
}

function UnderConstruction({ previousStep, nextStep }) {
  return (
    <div>
      THIS PAGE IS STILL BEING DEVELOPED
      <ButtonArea>
        {previousStep && (
          <Button
            className="button-primary-outline"
            onClick={() => push(previousStep.path)}
          >
            Previous Step
          </Button>
        )}
        {nextStep && (
          <Button
            className="button-primary"
            onClick={() => goToNextStep(nextStep)}
          >
            Continue
          </Button>
        )}
      </ButtonArea>
    </div>
  )
}

UnderConstruction.propTypes = exact(propTypes)
UnderConstruction.defaultProps = defaultProps

export default React.memo(UnderConstruction)
