import { ZERO_INCOME_SECTIONS, INITIAL_FORM_VALUES } from 'config'
import uuid from 'react-uuid'

function createInitialZeroIncomes({ householdMember, existingIncomes = {} }) {
  const initialIncomes = existingIncomes
  ZERO_INCOME_SECTIONS.forEach((section) => {
    const questions = section.questions

    // if this is part of the questions for the householdMember,
    // then include it in the incomes to create, skip if already exists
    questions.forEach(({ questionType }) => {
      if (initialIncomes[questionType]) return

      initialIncomes[questionType] = [
        {
          ...INITIAL_FORM_VALUES.ZERO_INCOMES,
          key: uuid(),
          householdMemberSfid: householdMember.sfid,
          shortenedType: questionType,
        },
      ]
    })
  })

  return initialIncomes
}

export default createInitialZeroIncomes
