import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, RadioGroup, CurrencyInput } from 'components'
import { BOOLEAN_OPTIONS } from 'config'
import exact from 'prop-types-exact'
import classNames from 'classnames'

const propTypes = {
  label: PropTypes.string.isRequired,
  applies: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  assetIndex: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
  assetLabels: PropTypes.object,
}

const defaultProps = {}

function AssetField({
  label,
  assetIndex,
  questionIndex,
  applies,
  assetLabels,
}) {
  const [hasAsset, setHasAsset] = useState(applies || false)
  const handleOptionChange = (event) => {
    const value = event.target.value
    setHasAsset(value)
  }
  return (
    <>
      <div className={classNames('row', { 'bottom-border': !hasAsset })}>
        <div className="col-12">
          <RadioGroup
            name={`householdAssets[${assetIndex}].applies`}
            label={`${questionIndex + 1}. ${label}`}
            options={BOOLEAN_OPTIONS}
            onChange={handleOptionChange}
            required
          />
        </div>
      </div>

      {hasAsset && (
        <>
          <div className="row">
            <div className="col-6">
              <CurrencyInput
                name={`householdAssets[${assetIndex}].amount`}
                label={assetLabels?.amount || 'Amount'}
                required
              />
            </div>
            <div className="col-6">
              <Input
                name={`householdAssets[${assetIndex}].source`}
                label={assetLabels?.source || 'Bank'}
                required
              />
            </div>
          </div>
          <div className="row bottom-border">
            <div className="col-6">
              <CurrencyInput
                name={`householdAssets[${assetIndex}].income`}
                label={assetLabels?.income || 'Income Accrued'}
                required
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

AssetField.propTypes = exact(propTypes)
AssetField.defaultProps = defaultProps

export default React.memo(AssetField)
